import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";

export interface ScadaFilterValues {
  inAgencies: number[];
  interventionDate: string | null;
  notInPlantStatusTypes: string[];
  notSynchronised: boolean;
  startOperationDate: string | null;
  endOperationDate: string | null;
  platform: string | null;
  plantTags: string[];
  equipments: string[];
  checkpointStatuses: string[];
}

export interface ScadaFilterValuesState {
  value: ScadaFilterValues;
}

const initialState: ScadaFilterValuesState = {
  value: {
    inAgencies: [],
    interventionDate: null,
    notInPlantStatusTypes: [],
    notSynchronised: true,
    startOperationDate: null,
    endOperationDate: null,
    platform: null,
    plantTags: [],
    equipments: [],
    checkpointStatuses: [],
  },
};

export const scadaFilterValuesSlice = createSlice({
  name: "scadaFilterValues",
  initialState,
  reducers: {
    setInAgencies: (state, action) => {
      state.value.inAgencies = action.payload;
    },
    setInterventionDate: (state, action) => {
      state.value.interventionDate = action.payload;
    },
    setNotSynchronised: (state, action) => {
      state.value.notSynchronised = action.payload;
    },
    removeNotInPlantStatusTypes: (state, action) => {
      state.value.notInPlantStatusTypes = state.value.notInPlantStatusTypes.filter((x) => x !== action.payload);
    },
    addNotInPlantStatusTypes: (state, action) => {
      state.value.notInPlantStatusTypes.push(action.payload);
    },
    setStartOperationDate: (state, action) => {
      state.value.startOperationDate = action.payload;
    },
    setEndOperationDate: (state, action) => {
      state.value.endOperationDate = action.payload;
    },
    setPlatform: (state, action) => {
      state.value.platform = action.payload;
    },
    setPlantTags: (state, action) => {
      state.value.plantTags = action.payload;
    },
    setEquipments: (state, action) => {
      state.value.equipments = action.payload;
    },
    setCheckpointStatuses: (state, action) => {
      state.value.checkpointStatuses = action.payload;
    },
  },
});

export const {
  setInAgencies,
  setInterventionDate,
  setNotSynchronised,
  removeNotInPlantStatusTypes,
  addNotInPlantStatusTypes,
  setStartOperationDate,
  setEndOperationDate,
  setPlatform,
  setPlantTags,
  setEquipments,
  setCheckpointStatuses,
} = scadaFilterValuesSlice.actions;

export const selectFilterValues = (state: RootState) => state.scadaFilterValues.value;

export default scadaFilterValuesSlice.reducer;
