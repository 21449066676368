import {useEffect, useState} from "react";
import {SolarGateway} from "../../../../../interfaces/solar_gateway/SolarGateway";
import {SolarGatewayPlanning as SolarGatewayPlanningInterface} from "../../../../../interfaces/solar_gateway/SolarGatewayPlanning";
import CardRow from "../CardRow";

interface propsInterface {
  solarGateway: SolarGateway;
}

export default function SolarGatewayPlanning(props: propsInterface) {
  const [planning, setPlanning] = useState<SolarGatewayPlanningInterface | null>(null);
  const days = [
    {label: "Lundi", key: "monday" as const},
    {label: "Mardi", key: "tuesday" as const},
    {label: "Mercredi", key: "wednesday" as const},
    {label: "Jeudi", key: "thursday" as const},
    {label: "Vendredi", key: "friday" as const},
    {label: "Samedi", key: "saturday" as const},
    {label: "Dimanche", key: "sunday" as const},
  ];

  useEffect(() => {
    const currentPlanning: SolarGatewayPlanningInterface = JSON.parse(props.solarGateway.planning);
    setPlanning(currentPlanning);
  }, [props.solarGateway]);

  return (
    <div>
      <p style={{maxWidth: "600px"}}>
        Le Routeur Solaire est programmé pour faire fonctionner l'équipement piloté sur des plages horaires planifiées,
        quelque soit la production solaire disponible.
      </p>
      {planning && (
        <div>
          {days.map(({label, key}) => (
            <CardRow key={key} label={label}>
              {planning[key][0].start_time + " : " + planning[key][0].end_time}
            </CardRow>
          ))}
        </div>
      )}
    </div>
  );
}
