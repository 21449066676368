import {Divider} from "@mui/material";

import CardRow from "../CardRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../../store/store";
import React, {useEffect, useRef, useState} from "react";
import NumberPvPanelForm from "./form/NumberPvPanelForm";
import PvPanelTypeForm from "./form/PvPanelTypeForm";
import {getPvPanelTypes} from "../../../../../store/form/pvPanelTypes.store";
import {Tracker} from "../../../../../interfaces/Tracker";
import ImperativeFormHandle from "../../../../../interfaces/ImperativeFormHandle";

interface propsInterface {
  index: number;
}

export function TrackerEquipment(props: propsInterface) {
  const trackers = useSelector((state: RootState) => state.trackers);
  const [isPvPanelTypeEditing, setIsPvPanelTypeEditing] = useState(false);
  const [isPvPanelNumberEditing, setIsPvPanelNumberEditing] = useState(false);

  const numberPvPanelFormRef = useRef<ImperativeFormHandle>(null);
  const pvPanelTypeFormRef = useRef<ImperativeFormHandle>(null);

  const dispatch = useDispatch<AppDispatch>();
  const pvPanelTypesList = useSelector((state: RootState) => state.pvPanelTypes.data);

  const handleNumberPvPanelSubmit = () => {
    if (numberPvPanelFormRef && numberPvPanelFormRef.current) {
      numberPvPanelFormRef.current.submitForm();
    }
  };

  const handlePvPanelTypeSubmit = () => {
    if (pvPanelTypeFormRef && pvPanelTypeFormRef.current) {
      pvPanelTypeFormRef.current.submitForm();
    }
  };

  const hasTracking = (tracker: Tracker) => {
    return tracker.trackerTypeLabel != "Structure au sol";
  };

  const hasAnemometer = (tracker: Tracker) => {
    return tracker.trackerTypeLabel != "Structure au sol";
  };

  useEffect(() => {
    if (pvPanelTypesList.length === 0) dispatch(getPvPanelTypes());
  }, []);

  return (
    <div>
      <h4>Tracker</h4>
      {hasTracking(trackers.data[props.index]) && (
        <CardRow label="Type de tracking">{trackers.data[props.index].trackingType}</CardRow>
      )}

      <CardRow
        label="Panneaux solaires"
        isEditable={true}
        childrenEditValidate={handlePvPanelTypeSubmit}
        isEditing={isPvPanelTypeEditing}
        isLoading={trackers.loading}
        onEditChange={(isEditing) => setIsPvPanelTypeEditing(isEditing)}
        childrenEdit={
          <PvPanelTypeForm
            ref={pvPanelTypeFormRef}
            index={props.index}
            onSuccess={() => setIsPvPanelTypeEditing(false)}
          />
        }>
        {trackers.data[props.index].pvPanel}
      </CardRow>

      <CardRow
        label="Nombre de panneaux"
        isEditable={true}
        childrenEditValidate={handleNumberPvPanelSubmit}
        isEditing={isPvPanelNumberEditing}
        isLoading={trackers.loading}
        onEditChange={(isEditing) => setIsPvPanelNumberEditing(isEditing)}
        childrenEdit={
          <NumberPvPanelForm
            ref={numberPvPanelFormRef}
            index={props.index}
            onSuccess={() => setIsPvPanelNumberEditing(false)}
          />
        }>
        {trackers.data[props.index].moduleQuantity}
      </CardRow>

      {hasAnemometer(trackers.data[props.index]) && (
        <CardRow label="Anémomètre">{trackers.data[props.index].anemometer}</CardRow>
      )}
      {trackers.data[props.index].linearActuatorType && (
        <CardRow label="Vérin">{trackers.data[props.index].linearActuatorType?.label}</CardRow>
      )}

      <div style={{marginTop: "20px"}}></div>
      <Divider></Divider>

      <h4>Onduleur</h4>

      <CardRow label="Référence">{trackers.data[props.index].inverter}</CardRow>
    </div>
  );
}
