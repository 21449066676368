import {Tracker} from "../interfaces/Tracker";
import {compareVersions} from "compare-versions";

const DataloggerService = {
  /**
   * compareVersion can't compare 'alphaX' 'betaX' 'rcX' version, we need to split to production version (ex: 1.1.0.alpha3 = 1.1.0)
   * @param tracker need to be master tracker for equipement (immersionHeater, ess, solarGateway..)
   * @param minimumOkwindAppVersion
   * @returns boolean
   */
  isCompatibleEmbeddedVersion: (tracker: Tracker | null, minimumOkwindAppVersion: string) => {
    if (!tracker) {
      return false;
    }
    const currentVersion = tracker.okwindAppVersion.slice(0, tracker.okwindAppVersion.lastIndexOf("."));
    return compareVersions(currentVersion, minimumOkwindAppVersion) >= 0;
  },
};

export default DataloggerService;
