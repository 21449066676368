import apiV2 from "./api/ApiV2";
import {forkJoin, lastValueFrom} from "rxjs";
import {PlantObjects} from "../store/scada/plant/PlantObjectsSlice";
import {Tracker} from "../interfaces/Tracker";
import {Meter} from "../interfaces/Meter";
import {ImmersionHeater} from "../interfaces/immersion_heater/ImmersionHeater";
import {Ess} from "../interfaces/ess/Ess";
import {SolarGateway} from "../interfaces/solar_gateway/SolarGateway";
import {Plant} from "../interfaces/Plant";

export async function GetPlantObjects(plantId: number): Promise<PlantObjects> {
  const plantObjectsObservable = forkJoin({
    plant: apiV2.plant(plantId),
    trackers: apiV2.trackers(plantId),
    immersionHeaters: apiV2.immersionHeaters(plantId),
    ess: apiV2.ess(plantId),
    solarGateways: apiV2.solarGateways(plantId),
    meter: apiV2.meter(plantId),
  });

  const data = await lastValueFrom(plantObjectsObservable);

  const plant: Plant = data.plant.ok ? await data.plant.json() : null;
  const trackers: Tracker[] = data.trackers.ok ? await data.trackers.json() : [];
  const meter: Meter[] = data.meter.ok ? await data.meter.json() : [];
  const immersionHeaters: ImmersionHeater[] = data.immersionHeaters.ok ? await data.immersionHeaters.json() : [];
  const ess: Ess[] = data.ess.ok ? await data.ess.json() : [];
  const solarGateways: SolarGateway[] = data.solarGateways.ok ? await data.solarGateways.json() : [];

  const plantObjects = {
    plant: plant,
    trackers: trackers.map((x) => {
      return {isSelected: true, tracker: x};
    }),
    immersionHeaters: immersionHeaters.map((x) => {
      return {isSelected: true, immersionHeater: x};
    }),
    ess: ess.map((x) => {
      return {isSelected: true, ess: x};
    }),
    solarGateways: solarGateways.map((x) => {
      return {isSelected: true, solarGateway: x};
    }),
    meter: {
      isSelected: true,
      meter: meter.length > 0 ? meter[0] : null,
    },
  } as PlantObjects;

  return plantObjects;
}
