import dayjs from "dayjs";
import React, {useRef, useState} from "react";
import {Plant} from "../../../../../interfaces/Plant";
import CardRow from "../CardRow";
import TagList from "../../../../../component/TagList";
import InterventionDateForm from "./form/InterventionDateForm";
import {formatDateTime} from "../../../../../service/format/FormatDateTime";
import PlantBanner from "../../../../../component/molecules/PlantBanner";
import ImperativeFormHandle from "../../../../../interfaces/ImperativeFormHandle";

interface propsInterface {
  plant: Plant;
}

export default function PlantGeneral(props: propsInterface) {
  const [isInterventionDateEditing, setIsInterventionDateEditing] = useState(false);

  const interventionDateFormRef = useRef<ImperativeFormHandle>(null);

  const handleDateChange = () => {
    if (interventionDateFormRef && interventionDateFormRef.current) {
      interventionDateFormRef.current.submitForm();
    }
  };
  return (
    <div>
      <PlantBanner plant={props.plant} />
      <h4>Général</h4>

      <CardRow label="Numéro de série de l'installation" isCopyEnable={true}>
        {props.plant.serialNumber}
      </CardRow>

      <CardRow label="Identifiant" isCopyEnable={true}>
        {props.plant.id}
      </CardRow>

      <CardRow label="Agence">{props.plant.agency}</CardRow>

      <CardRow label="Puissance nominale de l'installation">{props.plant.nominalPower} W</CardRow>

      <CardRow label="Date de mise en route">{formatDateTime(props.plant.operationDate)}</CardRow>

      <CardRow
        label="Date d'intervention"
        isEditable={true}
        children={
          props.plant.interventionDate ? dayjs(new Date(props.plant.interventionDate)).format("DD/MM/YYYY") : "Aucune"
        }
        isEditing={isInterventionDateEditing}
        onEditChange={(isEditing) => setIsInterventionDateEditing(isEditing)}
        childrenEditValidate={handleDateChange}
        tooltipTitle="Déclarer ici la date d'intervention prévue permet de filtrer dans la vue parc sur cette information"
        childrenEdit={
          <InterventionDateForm
            plantId={props.plant.id}
            interventionDate={props.plant.interventionDate}
            ref={interventionDateFormRef}
            onSuccess={() => setIsInterventionDateEditing(false)}
          />
        }></CardRow>

      <CardRow label="Tags">
        <TagList tags={props.plant.tags.map((tag) => ({label: tag}))} justifyChips={"flex-start"} limitTags={2} />
      </CardRow>
    </div>
  );
}
