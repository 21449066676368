import {ChartData, ChartDataset, ChartOptions} from "chart.js";
import {Line} from "react-chartjs-2";
import {convertKilo, mergeObjectsRecursive} from "../../../../utils/utils";
import {Dayjs} from "dayjs";
import {useTheme, alpha} from "@mui/material";
import {useEffect, useState, useRef} from "react";
import {formatXLabels, generateDates} from "../DateService";
import apiV2 from "../../../../service/api/ApiV2";
import {fillMissingDate, removeUnnecessaryDate} from "../ParseRawData";
import {Plant} from "../../../../interfaces/Plant";
import {EssSoC} from "../../../../interfaces/ess/EssSoC";
import {forkJoin} from "rxjs";
import {CrosshairPlugin} from "../../../../component/chartjs-plugin-crosshair/chartjs-plugin-crosshair";
import {ResizableBox} from "react-resizable";
import "react-resizable/css/styles.css";

import GraphResizeService from "../../../../service/GraphResizeService";
import {Ess} from "../../../../interfaces/ess/Ess";

type EssSoCChartProps = {
  plant: Plant;
  ess: Ess[];
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
};

const SOC_DEFAULT_LABEL = "Seuil de décharge";
const SOC_DEFAULT_VALUE = 20;

export function EssSoCChart(props: EssSoCChartProps) {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  const defaultOptions: ChartOptions<"line"> = {
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          callback: (tickValue: string | number) => `${tickValue as number}%`,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: "SoC ESS (%)",
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const rawValue = context.raw;
            const kWhValue = convertKilo((rawValue * props.ess[0].ratedCapacity) / 100, 1); // TODO: le multiple ESS avec capacité différente n est pas à gerer pour l instant
            return `SoC ${props.ess[0].name} ${kWhValue.toFixed(2)} kWh`;
          },
        },
        filter: function (tooltipItem) {
          return tooltipItem.dataset.label !== SOC_DEFAULT_LABEL;
        },
      },
    },
  };
  const options = mergeObjectsRecursive(defaultOptions, props.options);
  const [chartData, setChartData] = useState<ChartData<"line">>({
    labels: [],
    datasets: [],
  });

  const [width, setWidth] = useState(0);

  useEffect(() => {
    GraphResizeService.checkResizeWidth(ref, width, setWidth);
  }, [ref, width, setWidth]);

  useEffect(() => {
    const expectedDates = generateDates(props.startDate, props.endDate);

    async function updateDatasets(datasets: ChartDataset<"line">[]): Promise<void> {
      const nbColors = theme.palette.essSoCCurves.length;
      const tasks = [];

      // TODO: le niveau de decharge dynamique n est pas à gérer pour l instant default => SOC_DEFAULT_VALUE
      datasets.push({
        label: SOC_DEFAULT_LABEL,
        data: expectedDates.map(() => SOC_DEFAULT_VALUE),
        borderColor: theme.palette.essSoCCurves[2],
        backgroundColor: alpha(theme.palette.essSoCCurves[2], 0.3),
        borderWidth: 2,
        fill: "origin",
        tension: 0.4,
        order: 1000,
      });

      for (const currentEss of props.ess) {
        if (currentEss.operatingDate) {
          // L'appel est en erreur 500 si pas d'opération date (plus simple de fixer le front que de corriger tous les appels api-v2)
          tasks.push(apiV2.essStateOfCharge(props.startDate, props.endDate, currentEss.id));
        }
      }
      await Promise.all(tasks).then((results) => {
        for (let i = 0; i < results.length; i++) {
          results[i] = removeUnnecessaryDate(results[i]);
          fillMissingDate(results[i], expectedDates);
          datasets.push({
            label: `SoC ${props.ess[i].name}`,
            data: results[i].map((x: EssSoC) => {
              if (x.level === null) return null;
              return (x.level / props.ess[0].ratedCapacity) * 100;
            }),
            borderColor: theme.palette.essSoCCurves[i % nbColors],
            backgroundColor: theme.palette.essSoCCurves[i % nbColors],
            borderWidth: 2,
          });
        }
      });
    }
    const datasets: ChartDataset<"line">[] = [];
    const waitForDatasets = forkJoin({
      datasets: updateDatasets(datasets),
    });
    waitForDatasets.subscribe({
      next: () =>
        setChartData({
          labels: formatXLabels(expectedDates, props.plant),
          datasets: datasets,
        }),
      error: () => {
        setChartData({
          labels: formatXLabels(expectedDates, props.plant),
          datasets: [],
        });
      },
    });
  }, [props, theme]);

  const chartHeight = 180;

  return (
    <div ref={ref} className="scada-card-zoom">
      <ResizableBox
        transformScale={0.75}
        className="box"
        width={width}
        height={chartHeight}
        resizeHandles={["s"]}
        minConstraints={[100, chartHeight]}
        maxConstraints={[width, chartHeight * 4]}>
        {/* crosshair plugin is registered locally otherwise it can cause bug on non Line Chart*/}
        <Line options={options} data={chartData} plugins={[CrosshairPlugin]} style={{marginLeft: "15px"}} />
      </ResizableBox>
    </div>
  );
}
