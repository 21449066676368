import {Plant} from "../../../../interfaces/Plant";
import {Ess} from "../../../../interfaces/ess/Ess";
import {Dayjs} from "dayjs";
import {ChartOptions} from "chart.js";
import {EssEventChart} from "./EssEventChart";

type EssEventChartsProps = {
  plant: Plant;
  ess: Ess[];
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
};

export function EssEventCharts(props: EssEventChartsProps) {
  return (
    <>
      {props.ess.map((ess) => {
        return (
          <EssEventChart
            key={ess.id}
            ess={ess}
            startDate={props.startDate}
            endDate={props.endDate}
            plant={props.plant}
            options={props.options}
          />
        );
      })}
    </>
  );
}
