import React, {forwardRef, useImperativeHandle} from "react";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../../../store/store";
import {SubmitHandler, useForm} from "react-hook-form";
import {SetPlantConsumptionLevel} from "../../../../../../interfaces/plant/SetPlantConsumptionLevel";
import {ConsumptionLevelEnum} from "../../../../../../interfaces/plant/ConsumptionLevelEnum";
import {updatePlantConsumptionLevel} from "../../../../../../store/scada/plant/PlantObjectsSlice";
import ControlledSelect from "../../../../../../component/form/ControlledSelect";
import {getListConsumptionLevel} from "../../../../../../service/format/FormatConsumptionLevel";
import ImperativeFormHandle from "../../../../../../interfaces/ImperativeFormHandle";

type ConsumptionLevelFormProps = {
  plantId: number;
  consumptionLevel: string | null;
  onSuccess: () => void;
};

const ConsumptionLevelForm = forwardRef<ImperativeFormHandle, ConsumptionLevelFormProps>(
  (props: ConsumptionLevelFormProps, ref) => {
    const dispatch = useDispatch<AppDispatch>();

    const {control, handleSubmit, trigger} = useForm<SetPlantConsumptionLevel>({
      defaultValues: {
        plantId: props.plantId,
        consumptionLevel: props.consumptionLevel ? props.consumptionLevel : ConsumptionLevelEnum.UPSTREAM_PLUS,
      },
    });

    useImperativeHandle(ref, () => ({
      submitForm() {
        trigger();
        handleSubmit(onSubmit)();
      },
    }));

    const onSubmit: SubmitHandler<SetPlantConsumptionLevel> = async (data) => {
      await dispatch(updatePlantConsumptionLevel(data));
      props.onSuccess();
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete={"off"}>
          <div>
            <ControlledSelect
              required
              fullWidth
              name={"consumptionLevel"}
              size={"small"}
              style={{marginTop: 20, minWidth: isMobile ? 180 : 195}}
              label={"Mesure de consommation"}
              id={"consumptionLevel"}
              control={control}
              tooltipLabelText="BIDIRECTIONNEL + : L'onduleur est raccordé en aval de la mesure de consommation
                              BIDIRECTIONNEL - :  L'onduleur est raccordé en aval de la mesure de consommation inversée
                              MONODIRECTIONNEL + : L'onduleur est raccordé en amont de la mesure de consommation
                              MONODIRECTIONNEL - : L'onduleur est raccordé en amont de la mesure de consommation inversée"
              TooltipIcon={HelpOutlineIcon}
              variant={"outlined"}
              options={[
                ...getListConsumptionLevel().map((consumptionLevel) => ({
                  value: consumptionLevel.value,
                  label: consumptionLevel.label,
                })),
              ]}
            />
          </div>
        </form>
      </>
    );
  },
);

export default ConsumptionLevelForm;
