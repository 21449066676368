import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchTrackerTypes} from "../../store/form/trackerTypes.store";
import {AppDispatch, RootState} from "../../store/store";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import ListSubheader from "@mui/material/ListSubheader";
import FormHelperText from "@mui/material/FormHelperText";

import {TrackerType} from "../../interfaces/plant/TrackerType";
import {PlatformTypeEnum} from "../../interfaces/client/PlatformTypeEnum";

export default function SelectTrackerType(props: any) {
  const dispatch = useDispatch<AppDispatch>();
  const trackerTypes = useSelector((state: RootState) => state.trackerTypes.data);

  useEffect(() => {
    if (trackerTypes.length === 0) {
      dispatch(fetchTrackerTypes());
    }
  }, []);

  const {register, options, name, ...selectProps} = props;

  const getMenuTrackerType = () => {
    const menu: JSX.Element[] = [];
    const menuItemsDeprecated: JSX.Element[] = [];
    const menuItemsNew: JSX.Element[] = [];

    function addTrackerTypeToMenu(trackerType: TrackerType) {
      const destination = trackerType.deprecated ? menuItemsDeprecated : menuItemsNew;
      destination.push(
        <MenuItem
          data-cy={`cypressSelectTrackerTypeOption.${name}.${trackerType.id}`}
          key={trackerType.id}
          value={trackerType.id}>
          {" "}
          {trackerType.label}{" "}
        </MenuItem>,
      );
    }
    // à modifier quand les types de tracker seront associés au type de platform
    trackerTypes.forEach((trackerType) => {
      if (props.platformtype === PlatformTypeEnum.LUMIOO && trackerType.label.includes("LUMIOO")) {
        addTrackerTypeToMenu(trackerType);
      } else if (props.platformtype === PlatformTypeEnum.OKWIND && !trackerType.label.includes("LUMIOO")) {
        addTrackerTypeToMenu(trackerType);
      }
    });

    menu.push(<ListSubheader key="-1">Nouvelle gamme</ListSubheader>);
    menuItemsNew.forEach((element) => menu.push(element));
    menu.push(<ListSubheader key="-2">Ancienne gamme</ListSubheader>);
    menuItemsDeprecated.forEach((element) => menu.push(element));

    return menu;
  };

  return (
    <div>
      <InputLabel id="selectTrackerTypeLabel" error={props.error} required={props.required}>
        Référence tracker
      </InputLabel>
      <Select
        {...selectProps}
        {...register(name, options)}
        labelId="selectTrackerTypeLabel"
        id="selectTrackerType"
        data-cy={`cypressSelectTrackerType.${name}`}
        value={props.preloadvalue === undefined || props.preloadvalue === null ? "" : props.preloadvalue}
        label="Référence tracker"
        color="primary"
        required={props.required}>
        {getMenuTrackerType()}
      </Select>
      {props.error && <FormHelperText error>{props.helpertext}</FormHelperText>}
    </div>
  );
}
