import * as React from "react";
import {useTheme} from "@mui/material";

export default function BetaLabel(): React.JSX.Element {
  const theme = useTheme();

  return (
    <span
      style={{
        backgroundColor: theme.palette.primary.main,
        color: "white",
        marginRight: 2,
        padding: 3,
      }}>
      BETA
    </span>
  );
}
