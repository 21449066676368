import * as React from "react";
import {Battery} from "../../../../../interfaces/ess/Battery";
import CardRow from "../CardRow";

type Props = {
  battery: Battery;
};

export default function BatteryDetail(props: Props): React.JSX.Element {
  return (
    <div>
      <CardRow label="Fabricant">{props.battery.type.brand}</CardRow>
      <CardRow label="Référence">{props.battery.type.label}</CardRow>
      <CardRow label="Capacité nominale">{props.battery.type.ratedCapacity} Wh</CardRow>
      <CardRow label="Firmware version">{props.battery.firmwareVersion}</CardRow>
    </div>
  );
}
