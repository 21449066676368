import {Ess} from "../../../../../interfaces/ess/Ess";
import AppCollapse from "../AppCollapse";
import ConverterDetail from "./ConverterDetail";

interface propsInterface {
  ess: Ess;
}

export default function EssController(props: propsInterface) {
  return (
    <div>
      <p>
        {" "}
        {props.ess.name} se compose de {props.ess.converters.length} convertisseurs
      </p>
      {props.ess.converters.map((converter) => {
        return (
          <AppCollapse small={true} title={"Numéro: " + converter.number + " SN: " + converter.serialNumber}>
            <ConverterDetail converter={converter} />
          </AppCollapse>
        );
      })}
    </div>
  );
}
