import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {
  Box,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {HttpRequestHeader} from "antd/es/upload/interface";
import sizeaApi from "../../../../service/api/ApiSizea";
import Simulation, {HotWaterTankTypeRef} from "../model";
import {v4 as uuidv4} from "uuid";
import {Upload} from "antd";
import {SelectImmersionHeaterType} from "../component/SelectImmersionHeaterType";
import AppButton from "../../../../component/atom/button/AppButton";
import AppImmersionHeaterIcon from "../../../../component/atom/icon/AppImmersionHeaterIcon";
import AppFormLine from "./SizeaFormLine";
import SizeaRemoveButton from "./SizeaRemoveButton";
import SizeaNoFormLine from "./SizeaNoFormLine";
import {uploadOnChange} from "./UploadOnChange";

type PropsType = {
  watchFieldArray: any[];
  control: Control<Simulation, any>;
  register: UseFormRegister<Simulation>;
  setValue: UseFormSetValue<Simulation>;
  watch: UseFormWatch<Simulation>;
  errors: FieldErrors<Simulation>;
  fields: FieldArrayWithId<Simulation, "waterPointInputs", "id">[];
  append: UseFieldArrayAppend<Simulation, "waterPointInputs">;
  remove: UseFieldArrayRemove;
  clearErrors: UseFormClearErrors<Simulation>;
};

export default function SizeaFormImmersionHeaters(props: PropsType) {
  const controlledFields = props.fields.map((fieldsHotWaterRequirements, index) => {
    return {
      ...fieldsHotWaterRequirements,
      ...props.watchFieldArray[index],
    };
  });

  return (
    <>
      {props.fields.length === 0 && (
        <SizeaNoFormLine
          logo={<AppImmersionHeaterIcon />}
          label="Aucun stockage thermique pour cette étude"></SizeaNoFormLine>
      )}
      <List>
        {controlledFields.map((item, index) => {
          return (
            <AppFormLine key={item.id} logo={<AppImmersionHeaterIcon />}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={11}>
                  <ListItem>
                    <List>
                      <FormControl>
                        <FormLabel required id="storage-label">
                          Type d'installation
                        </FormLabel>
                        <Controller
                          rules={{required: true}}
                          control={props.control}
                          name={`waterPointInputs.${index}.hotWaterTankType`}
                          render={({field}) => (
                            <RadioGroup
                              row
                              {...field}
                              id={`hotWaterTankType-select-${index}`}
                              style={{marginLeft: 15}}
                              defaultValue={props.watch(`waterPointInputs.${index}.hotWaterTankType`)}>
                              <FormControlLabel value={HotWaterTankTypeRef.BALLON} control={<Radio />} label="Ballon" />
                              <FormControlLabel value={HotWaterTankTypeRef.CUVE} control={<Radio />} label="Cuve" />
                            </RadioGroup>
                          )}
                        />
                      </FormControl>

                      <TextField
                        required={props.watch(`waterPointInputs.${index}.hotWaterTankType`) === "CUVE"}
                        label="Volume cuve"
                        sx={{
                          m: 1,
                          width: "25%",
                          visibility:
                            props.watch(`waterPointInputs.${index}.hotWaterTankType`) === "CUVE" ? "visible" : "hidden",
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">Litre</InputAdornment>,
                        }}
                        {...props.register(`waterPointInputs.${index}.volumeCuve`, {
                          required: props.watch(`waterPointInputs.${index}.hotWaterTankType`) === "CUVE",
                          valueAsNumber: true,
                        })}
                      />
                      <ListItemText key="1">1. Besoin eau chaude </ListItemText>
                      <ListItem key="2">
                        <Controller
                          control={props.control}
                          name={`waterPointInputs.${index}.hotWaterRequirementFileUuid`}
                          rules={{
                            required: {
                              value: true,
                              message: "Le fichier de besoin en eau chaude est requis.",
                            },
                          }}
                          render={({fieldState}) => (
                            <FormControl error={!!fieldState.error}>
                              <Upload
                                className={`hotWaterRequirement-${index}-upload`}
                                name="file"
                                accept=".xlsx"
                                action={sizeaApi.getUploadUrl()}
                                headers={sizeaApi.getUploadHeaders() as unknown as HttpRequestHeader}
                                onChange={uploadOnChange}
                                data={{
                                  identifier: `hotwater-${props.watch(`waterPointInputs.${index}.hotWaterRequirementFileUuid`)}`,
                                  timezone: props.watch("plantInput.timezone"),
                                  startDate: props.watch("plantInput.startDate"),
                                  endDate: props.watch("plantInput.endDate"),
                                }}
                                onRemove={() => {
                                  props.setValue(`waterPointInputs.${index}.hotWaterRequirementFileUuid`, null);
                                }}
                                beforeUpload={() => {
                                  props.setValue(`waterPointInputs.${index}.hotWaterRequirementFileUuid`, uuidv4());
                                  props.clearErrors(`waterPointInputs.${index}.hotWaterRequirementFileUuid`);
                                }}
                                listType="picture-card"
                                showUploadList={true}
                                maxCount={1}
                                style={{fontFamily: "Montserrat"}}
                                defaultFileList={
                                  props.watch(`waterPointInputs.${index}.hotWaterRequirementFileUuid`)
                                    ? [
                                        {
                                          uid:
                                            props.watch(`waterPointInputs.${index}.hotWaterRequirementFileUuid`) || "",
                                          name: "Fichier de besoin en eau chaude indiqué par l'URL",
                                        },
                                      ]
                                    : []
                                }>
                                Déposez vos fichiers ici
                              </Upload>
                              <FormHelperText className="Mui-required">{fieldState.error?.message}</FormHelperText>
                            </FormControl>
                          )}
                        />
                      </ListItem>
                      <ListItemText key="3">2. Consigne de température</ListItemText>
                      <ListItem key="4">
                        <Grid container>
                          <Grid item xs={3}>
                            <TextField
                              required
                              type="number"
                              label="Température"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">°C</InputAdornment>,
                              }}
                              {...props.register(`waterPointInputs.${index}.targetTemperature`, {
                                required: true,
                                valueAsNumber: true,
                              })}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItemText key="5">3. Produits à installer</ListItemText>
                      <ListItem key="6">
                        <Grid container>
                          <FormControl>
                            <InputLabel required id="immersion-heater-label">
                              Ref thermoplongeur
                            </InputLabel>
                            <SelectImmersionHeaterType
                              sx={{
                                minWidth: 300,
                                maxWidth: 300,
                              }}
                              hotwatertanktype={props.watch(`waterPointInputs.${index}.hotWaterTankType`)}
                              control={props.control}
                              error={!!props.errors.waterPointInputs?.[index]?.hotWaterTankType}
                              labelId="immersion-heater-label"
                              label={"Ref thermoplongeur"} // masqué, mais prépare une place quand le label est shrinked, et évite que la ligne passe à travers du label
                              name={`waterPointInputs.${index}.immersionHeaterTypeId`}
                              register={props.register}
                              setValue={props.setValue}
                              defaultValue={item.immersionHeaterTypeId}
                            />
                          </FormControl>
                          <FormGroup sx={{marginLeft: 1}}>
                            <TextField
                              required
                              type="number"
                              label="Nombre"
                              {...props.register(`waterPointInputs.${index}.immersionHeaterQuantity`, {
                                required: true,
                                valueAsNumber: true,
                              })}
                            />
                          </FormGroup>
                        </Grid>
                      </ListItem>
                    </List>
                  </ListItem>
                </Grid>
                <Grid item xs={1}>
                  <SizeaRemoveButton
                    id={`removeHotWaterRequirement-${index}`}
                    onClick={() => {
                      props.remove(index);
                    }}
                  />
                </Grid>
              </Grid>
            </AppFormLine>
          );
        })}
      </List>
      <Box
        sx={{
          textAlign: "center",
        }}>
        <AppButton
          style="primary"
          label="Ajouter un stockage thermique"
          title="Ajouter un stockage thermique"
          id={"addWaterPointButton"}
          onClick={() => {
            props.append({
              uuid: uuidv4(),
              hotWaterTankType: HotWaterTankTypeRef.BALLON,
              immersionHeaterQuantity: 1,
              immersionHeaterTypeId: "",
              volumeCuve: null,
              targetTemperature: 70,
              hotWaterRequirementFileUuid: null,
            });
          }}
        />
      </Box>
    </>
  );
}
