import React, {useRef, useState} from "react";

import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/store";
import CardRow from "../CardRow";
import {ImmersionHeater} from "../../../../../interfaces/immersion_heater/ImmersionHeater";
import LegionellosisProtectionForm from "./form/LegionellosisProtectionForm";
import DataloggerService from "../../../../../service/DataloggerService";

import ImperativeFormHandle from "../../../../../interfaces/ImperativeFormHandle";

interface propsInterface {
  immersionHeater: ImmersionHeater;
}

const MINIMUM_OKWIND_APP_VERSION_LEGIONELLOSIS_PROTECTION_SETTING = "1.21.0";

export default function ImmersionHeaterSetting(props: propsInterface) {
  const [isLegionellosisEditing, setIsLegionellosisEditing] = useState(false);

  const immersionHeaters = useSelector((state: RootState) => state.immersionHeaters);
  const trackerMaster = useSelector((state: RootState) => state.trackers).trackerMaster;
  const formLegionellosisProtectionRef = useRef<ImperativeFormHandle>(null);

  const index = immersionHeaters.data.findIndex((immersionHeater) => immersionHeater.id === props.immersionHeater.id);

  const disabledLegionellosisProtection = !DataloggerService.isCompatibleEmbeddedVersion(
    trackerMaster,
    MINIMUM_OKWIND_APP_VERSION_LEGIONELLOSIS_PROTECTION_SETTING,
  );

  const handleAction = () => {
    if (formLegionellosisProtectionRef && formLegionellosisProtectionRef.current) {
      formLegionellosisProtectionRef.current.submitForm();
    }
  };

  return (
    <div>
      <h4>Configuration logiciel</h4>

      <CardRow label="Adresse">{props.immersionHeater.tcpAddress}</CardRow>

      <CardRow label="Alias">{props.immersionHeater.alias}</CardRow>

      <CardRow
        label="Protection legionellose"
        isEditable={true}
        isEditing={isLegionellosisEditing}
        isLoading={immersionHeaters.loading}
        onEditChange={(isEditing) => setIsLegionellosisEditing(isEditing)}
        helpTooltipText="L'activation de ce paramètre va déclencher une marche forcée quotidienne pour eviter le développement de la legionellose"
        disabledText={
          disabledLegionellosisProtection
            ? "La version Okwind App du tracker maître n'est pas à jour pour activer cette fonctionnalité. (version minimum : " +
              MINIMUM_OKWIND_APP_VERSION_LEGIONELLOSIS_PROTECTION_SETTING +
              ")"
            : ""
        }
        children={immersionHeaters.data[index].legionellosisProtection ? "Activée" : "Désactivée"}
        childrenEditValidate={handleAction}
        childrenEdit={
          <LegionellosisProtectionForm
            formRef={formLegionellosisProtectionRef}
            index={index}
            onSuccess={() => {
              setIsLegionellosisEditing(false);
            }}
          />
        }></CardRow>
    </div>
  );
}
