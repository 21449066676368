import CardRow from "../CardRow";
import {ImmersionHeater} from "../../../../../interfaces/immersion_heater/ImmersionHeater";

interface propsInterface {
  immersionHeater: ImmersionHeater;
}

export default function ImmersionHeaterEquipement(props: propsInterface) {
  return (
    <div>
      <h4>Stockage thermique</h4>

      <CardRow label="Type de stockage">{props.immersionHeater.hotWaterTankEntity.hotWaterTankType.label}</CardRow>

      <CardRow label="Type de chauffage client">{props.immersionHeater.hotWaterTankEntity.heaterType.label}</CardRow>

      <CardRow label="Volume du stockage">{props.immersionHeater.hotWaterTankEntity.volume} L</CardRow>
    </div>
  );
}
