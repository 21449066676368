import {useState} from "react";
import {Upload} from "antd";
import {UploadChangeParam} from "antd/es/upload";
import {UploadRequestOption} from "rc-upload/lib/interface";
import {useAppDispatch} from "../../../../../store/hooks";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {fetchCheckpointExecuteSoutirage} from "../../../../../store/control/checkpointsBlock.store";
import {LoadingButton} from "@mui/lab";
import {Button} from "@mui/material";

import DialogGetEnedisFile from "../../../../../component/DialogGetEnedisFile";
import apiEnedisAuthorized from "../../../../../service/KeycloakService";
import {Plant} from "../../../../../interfaces/Plant";

type UploadEnedisFileProps = {
  isLoading: boolean;
  plant: Plant;
};

export default function UploadEnedisFile(props: UploadEnedisFileProps) {
  const dispatch = useAppDispatch();

  const [enedisFileDialogOpen, setEnedisFileDialogOpen] = useState(false);

  function changeFile(param: UploadChangeParam) {
    const file = param.fileList.length > 0 ? param.fileList[0] : null; // do not use param.file property because it's not set to null when removing file
    if (props.plant.id && file?.status === "done") {
      dispatch(fetchCheckpointExecuteSoutirage([props.plant.id, file]));
    }
  }

  // to avoid the automatic post request of file
  const dummyRequest = (option: UploadRequestOption) => {
    setTimeout(() => {
      option.onSuccess!("ok");
    }, 0);
  };

  const handleGetEnedisFile = () => {
    setEnedisFileDialogOpen(true);
  };

  return (
    <>
      <Upload
        id="uploadEnedisFile"
        onChange={changeFile}
        customRequest={dummyRequest}
        maxCount={1}
        accept=".xlsx, .csv">
        <LoadingButton loading={props.isLoading} variant="outlined" startIcon={<CloudUploadIcon color="primary" />}>
          Fichier Enedis
        </LoadingButton>
      </Upload>
      {apiEnedisAuthorized() && (
        <>
          <Button
            color="primary"
            onClick={handleGetEnedisFile}
            sx={{
              textDecoration: "underline",
              textTransform: "none",
              padding: 0,
              minWidth: "auto",
            }}>
            Obtenir le fichier Enedis
          </Button>
          <DialogGetEnedisFile
            open={enedisFileDialogOpen}
            handleClose={() => setEnedisFileDialogOpen(false)}
            title="Données de consommation du client"
            actionLabel="Télécharger"
            plant={props.plant}
          />
        </>
      )}
    </>
  );
}
