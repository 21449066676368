import {Plant} from "../../../../interfaces/Plant";
import {Dayjs} from "dayjs";
import {ChartOptions} from "chart.js";
import {SolarGateway} from "../../../../interfaces/solar_gateway/SolarGateway";
import {SolarGatewayEventChart} from "./SolarGatewayEventChart";

type SolarGatewayEventChartsProps = {
  plant: Plant;
  solarGateways: SolarGateway[];
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
};

export function SolarGatewayEventCharts(props: SolarGatewayEventChartsProps) {
  return (
    <>
      {props.solarGateways.map((solarGateway) => {
        return (
          <SolarGatewayEventChart
            key={solarGateway.id}
            solarGateway={solarGateway}
            startDate={props.startDate}
            endDate={props.endDate}
            plant={props.plant}
            options={props.options}
          />
        );
      })}
    </>
  );
}
