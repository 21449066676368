import {Plant} from "../../../../interfaces/Plant";
import {Dayjs} from "dayjs";
import {ChartOptions} from "chart.js";
import {SolarGateway} from "../../../../interfaces/solar_gateway/SolarGateway";
import {ResizableBox} from "react-resizable";
import {Line} from "react-chartjs-2";
import {CrosshairPlugin} from "../../../../component/chartjs-plugin-crosshair/chartjs-plugin-crosshair";
import {AlarmDialog} from "../AlarmDialog";
import useEventChart from "../../../../hooks/useEventChart";
import apiV2 from "../../../../service/api/ApiV2";

type SolarGatewayEventChartProps = {
  plant: Plant;
  solarGateway: SolarGateway;
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
};

export function SolarGatewayEventChart(props: SolarGatewayEventChartProps) {
  const {chartData, options, openDialog, ref, width, chartRef, openAlarmDialog, setOpenAlarmDialog, alarmInfo} =
    useEventChart({
      plant: props.plant,
      startDate: props.startDate,
      endDate: props.endDate,
      options: props.options,
      fetchAlarms: apiV2.solarGatewayAlarms,
      id: props.solarGateway.id,
      title: "Ev RS",
    });

  const chartHeight = 90 + 20 * Object.keys(chartData.datasets).length;
  return (
    <div ref={ref} className="scada-card-zoom">
      <ResizableBox
        transformScale={0.75}
        className="box"
        width={width}
        height={chartHeight}
        resizeHandles={["s"]}
        minConstraints={[100, chartHeight]}
        maxConstraints={[width, chartHeight * 4]}>
        <Line
          ref={chartRef}
          onClick={openDialog}
          options={options}
          data={chartData}
          plugins={[CrosshairPlugin]}
          style={{marginLeft: "15px"}}
        />
      </ResizableBox>
      {alarmInfo && (
        <AlarmDialog open={openAlarmDialog} onClose={() => setOpenAlarmDialog(false)} alarmInfo={alarmInfo} />
      )}
    </div>
  );
}
