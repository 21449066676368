import dayjs, {Dayjs} from "dayjs";

export function duration(beginDate: Dayjs, endDate: Dayjs): string {
  const duration = endDate.diff(beginDate, "second");
  const days = Math.floor(duration / 86400);
  const hours = Math.floor((duration % 86400) / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  let durationString = "";
  if (days > 0) {
    durationString += `${days} jours, `;
  }
  if (hours > 0) {
    durationString += `${hours} heures, `;
  }
  if (minutes > 0 || durationString === "") {
    durationString += `${minutes} minutes`;
  }
  // remove potential trailing comma and space
  durationString = durationString.replace(/, $/, "");
  return durationString;
}

export function durationShort(beginDate: Dayjs, endDate: Dayjs): string {
  const duration = endDate.diff(beginDate, "second");
  const days = Math.floor(duration / 86400);
  const hours = Math.floor((duration % 86400) / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  if (days > 0) {
    return `${days} jours`;
  }
  if (hours > 0) {
    return `${hours} heures`;
  }
  return `${minutes} minutes`;
}

export function formatDateTime(date: string | null): string {
  if (date === null || date === undefined) {
    return "N/A";
  }

  return dayjs(date).tz(dayjs.tz.guess()).format("DD/MM/YYYY HH:mm");
}

export function formatDate(date: string | null): string {
  if (date === null || date === undefined) {
    return "N/A";
  }
  return dayjs(date).tz(dayjs.tz.guess()).format("DD/MM/YYYY");
}

export function formatDateShort(date: string | null): string {
  if (date === null || date === undefined) {
    return "N/A";
  }

  return dayjs(date).tz(dayjs.tz.guess()).format("D MMMM YYYY");
}

export function nbDaysUntilToday(date: string | null): string {
  if (date === null) {
    return "N/A";
  }

  const date1 = dayjs(date);
  const date2 = dayjs();
  const days = date2.diff(date1, "days");

  return days.toString();
}

export function formatCountdown(date: string | null): string {
  if (date === null) {
    return "N/A";
  }

  const date1 = dayjs(date);
  const today = dayjs();
  if (date1.isSame(today, "day")) {
    return "Aujourd'hui";
  } else {
    const days = today.diff(date1, "days") + 1;
    return `il y a ${days} jour` + (days > 1 ? "s" : "");
  }
}
