import React from "react";
import {ReactNode} from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import CircularProgress from "@mui/material/CircularProgress";
import ClipBoardButton from "../../../../service/ClipboardService";
import AppCustomWidthTooltip from "../../../../component/AppCustomWidthTooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

type CardRowProps = {
  label: string;
  children: ReactNode;
  isEditable?: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  isCopyEnable?: boolean;
  childrenEdit?: ReactNode;
  childrenEditValidate?: () => void;
  tooltipTitle?: string;
  onEditChange?: (isEditing: boolean) => void;
  helpTooltipText?: string;
  disabledText?: string;
};

const CardRow = (props: CardRowProps) => {
  const isEditing = props.isEditing ? props.isEditing : false;
  const isLoading = props.isLoading ? props.isLoading : false;

  return (
    <Grid container sx={{mt: "10px"}}>
      <Grid item xs={4} md={6} className="item-feature">
        {props.tooltipTitle && props.tooltipTitle !== "" ? (
          <div>
            <Tooltip title={props.tooltipTitle}>
              <p style={{marginTop: "0px", marginBottom: "0px"}}>{props.label}</p>
            </Tooltip>
          </div>
        ) : (
          <p style={{marginTop: "0px", marginBottom: "0px"}}>
            <Grid container>
              <Grid item xs={props.helpTooltipText && props.helpTooltipText != "" ? 9 : 12}>
                {props.label}
              </Grid>
              {props.helpTooltipText && props.helpTooltipText !== "" ? (
                <Grid item xs={3}>
                  <AppCustomWidthTooltip
                    title={<span style={{fontSize: "10px", whiteSpace: "pre-line"}}>{props.helpTooltipText}</span>}>
                    <HelpOutlineIcon style={{width: "18px", color: "grey"}} />
                  </AppCustomWidthTooltip>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </p>
        )}
      </Grid>
      <Grid item xs={6} md={5} style={{display: "flex", alignItems: "center"}}>
        {props.isEditable && isEditing ? (
          props.childrenEdit
        ) : (
          <span style={{display: "flex", alignItems: "center", maxWidth: "100%"}}>{props.children}</span>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        md={1}
        style={{
          margin: "auto",
        }}>
        {props.isEditable && !isEditing && (
          <Tooltip title={props.disabledText && props.disabledText !== "" ? props.disabledText : ""}>
            <span>
              {/* permet d afficher le tooltip malgrès le disabled activé*/}
              <IconButton
                style={{marginTop: "-2px", padding: "0px"}}
                color="secondary"
                edge="start"
                size="small"
                disabled={props.disabledText && props.disabledText !== "" ? true : false}
                onClick={() => props.onEditChange && props.onEditChange(true)}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {props.isCopyEnable && <ClipBoardButton text={props.children?.toString()}></ClipBoardButton>}
        {props.isEditable && isEditing && (
          <Box>
            <Grid container>
              <Grid item xs={6} style={{padding: "5px"}}>
                <Tooltip title="Valider la modification">
                  <IconButton
                    style={{padding: "0px"}}
                    color="primary"
                    edge="start"
                    size="small"
                    onClick={() => props.childrenEditValidate && props.childrenEditValidate()}>
                    {isLoading ? <CircularProgress style={{marginRight: "5px"}} size={22} /> : <DoneIcon />}
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6} style={{padding: "5px"}}>
                <Tooltip title="Annuler la modification">
                  <IconButton
                    style={{padding: "0px"}}
                    edge="start"
                    size="small"
                    onClick={() => {
                      props.onEditChange && props.onEditChange(false);
                    }}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CardRow;
