import {Divider} from "@mui/material";
import {TrackerAction} from "./TrackerAction";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/store";
import {Tracker} from "../../../../../interfaces/Tracker";
interface propsInterface {
  index: number;
}

export function TrackerActions(props: propsInterface) {
  const trackers = useSelector((state: RootState) => state.trackers);

  const trackerName = (tracker: Tracker) => {
    if (tracker.trackerTypeLabel == "Structure au sol") {
      return `Structure au sol`;
    } else {
      return `Tracker`;
    }
  };

  const hasTracking = (tracker: Tracker) => {
    return tracker.trackerTypeLabel != "Structure au sol";
  };

  return (
    <div>
      <h4>{trackerName(trackers.data[props.index])}</h4>

      {hasTracking(trackers.data[props.index]) && (
        <TrackerAction
          label="Redémarrer le tracking"
          value={"restart_tracking"}
          trackerId={trackers.data[props.index].id}></TrackerAction>
      )}
      {hasTracking(trackers.data[props.index]) && (
        <TrackerAction
          label="Mettre à plat"
          value={"start_flat"}
          trackerId={trackers.data[props.index].id}></TrackerAction>
      )}
      {hasTracking(trackers.data[props.index]) && (
        <TrackerAction
          label="Arrêter la mise à plat"
          value={"stop_flat"}
          trackerId={trackers.data[props.index].id}></TrackerAction>
      )}
      <TrackerAction
        label="Ouvrir le reverse SSH"
        value={"start_ssh"}
        trackerId={trackers.data[props.index].id}></TrackerAction>
      <TrackerAction
        label="Fermer le reverse SSH"
        value={"stop_ssh"}
        trackerId={trackers.data[props.index].id}></TrackerAction>

      <div style={{marginTop: "20px"}}>
        <Divider></Divider>
      </div>

      <h4>Plateforme</h4>

      <TrackerAction
        label="Purger les alarmes courantes"
        value={"tracker_alarm_end_collection"}
        trackerId={trackers.data[props.index].id}></TrackerAction>
    </div>
  );
}
