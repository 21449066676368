import React, {useRef, useState} from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import type {DialogProps} from "@mui/material";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import CloseIcon from "@mui/icons-material/Close";
import GetEnedisFileForm from "./GetEnedisFileForm";
import {Plant} from "../interfaces/Plant";
import ImperativeFormHandle from "../interfaces/ImperativeFormHandle";

type DialogGetEnedisFileProps = {
  title: string;
  actionLabel: string;
  open: boolean;
  handleClose: () => void;
  plant?: Plant;
};

export default function DialogUser(props: DialogGetEnedisFileProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const formEnedisFileRef = useRef<ImperativeFormHandle>(null);

  const handleAction = () => {
    if (formEnedisFileRef && formEnedisFileRef.current) {
      formEnedisFileRef.current.submitForm();
    }
  };

  const handleCloseDialog = () => {
    props.handleClose();
  };

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason === "backdropClick") return;
    handleCloseDialog();
  };

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  return (
    <Dialog fullScreen={isMobile} open={props.open} onClose={handleClose} style={{borderRadius: 22}}>
      <DialogTitle style={{padding: 0}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            {props.title}
          </Typography>
          <IconButton
            color="secondary"
            style={{background: theme.palette.dialogCloseButtonBackground}}
            aria-label="close dialog"
            edge="start"
            onClick={handleCloseDialog}>
            <CloseIcon
              style={{
                color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
              }}
            />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <Divider style={{margin: 0}}></Divider>
      <DialogContent>
        <GetEnedisFileForm
          ref={formEnedisFileRef}
          handleCloseDialog={handleCloseDialog}
          plant={props.plant}
          onDownloading={setIsDownloading}
        />
      </DialogContent>
      <DialogActions style={{paddingRight: "24px", paddingBottom: "24px"}}>
        <Button color="inherit" variant="outlined" onClick={handleCloseDialog}>
          Retour
        </Button>
        <Button
          disabled={isDownloading}
          id="downloadConfiguratorLink"
          variant="outlined"
          onClick={handleAction}
          endIcon={isDownloading ? <CircularProgress size={24} /> : <CloudDownloadIcon />}>
          {props.actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
