import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {CardMedia, Checkbox, Grid, IconButton, Tooltip} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import image from "../../../../../assets/solarGateway/icon_solar_gateway.svg";
import {SolarGateway} from "../../../../../interfaces/solar_gateway/SolarGateway";
import SolarGatewayDialog from "./SolarGatewayDialog";
import {useAppDispatch} from "../../../../../store/hooks";
import {toggleSolarGateway} from "../../../../../store/scada/plant/PlantObjectsSlice";
import AppCollapse from "../AppCollapse";
import {SolarGatewayStatus} from "../../../../../interfaces/solar_gateway/SolarGatewayStatus";
import apiV2 from "../../../../../service/api/ApiV2";
import SynchronisedDiv from "../SynchronisedDiv";
import {AlarmsList} from "../AlarmsList";

type SolarGatewayCardProps = {
  solarGateway: SolarGateway;
  isSelected: boolean;
};

export default function SolarGatewayCard(props: SolarGatewayCardProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [solarGatewayStatus, setSolarGatewayStatus] = useState<SolarGatewayStatus>();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleSolarGateway({id: props.solarGateway.id, isSelected: event.target.checked}));
  };

  useEffect(() => {
    const fetchStatus = async () => {
      const status = await apiV2.solarGatewayStatus(props.solarGateway.id);
      setSolarGatewayStatus(status);
    };
    fetchStatus();
  }, []);

  return (
    <Card className="scada-card">
      <Grid container style={{paddingTop: "15px", paddingLeft: "10px"}}>
        <Grid item xs={2}>
          <CardMedia
            style={{
              backgroundColor: theme.palette.mode === "dark" ? "#757575" : "transparent",
              padding: "2px",
              marginLeft: "15px",
            }}
            component="img"
            image={image}
            className="scada-card-image"
          />
        </Grid>
        <Grid item xs={8} style={{paddingLeft: "10px"}}>
          <Typography component="div" className="scada-card-title">
            Routeur Solaire
          </Typography>
          <SynchronisedDiv
            isSynchronised={
              solarGatewayStatus?.isSynchronised !== undefined ? solarGatewayStatus.isSynchronised : false
            }
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            marginTop: "-5px",
            paddingRight: "10px",
            textAlign: "right",
          }}>
          <Checkbox onChange={toggleSelect} checked={props.isSelected} />
          <Tooltip title="Paramètres">
            <IconButton aria-label="delete" onClick={handleOpen}>
              <SettingsIcon fontSize="inherit" color="secondary" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            paddingLeft: "10px",
            paddingRight: "18px",
            paddingBottom: "10px",
          }}>
          <AppCollapse small={false} title="Alarmes">
            {solarGatewayStatus !== undefined && solarGatewayStatus.alarms.length > 0 ? (
              <AlarmsList alarms={solarGatewayStatus.alarms} />
            ) : (
              <p>Aucune alarmes</p>
            )}
          </AppCollapse>
        </Grid>
      </Grid>
      <SolarGatewayDialog solarGateway={props.solarGateway} open={open} handleClose={handleClose}></SolarGatewayDialog>
    </Card>
  );
}
