import React, {useState} from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";

import {DialogProps, Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import CloseIcon from "@mui/icons-material/Close";
import TransitionSlideUp from "../../../../../service/transition/TransitionSlideUp";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";

import {Plant} from "../../../../../interfaces/Plant";
import PlantGeneral from "./PlantGeneral";
import PlantSetting from "./PlantSetting";
import PlantActions from "./PlantActions";
import PlantTraces from "./PlantTraces";
import PlantCustomer from "./PlantCustomer";

type PlantDialogProps = {
  plant: Plant;
  open: boolean;
  handleClose: () => void;
};

export default function PlantDialog(props: PlantDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [maxWidth] = useState<DialogProps["maxWidth"]>("lg");

  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    props.handleClose();
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      fullScreen={isMobile}
      open={props.open}
      onClose={handleClose}
      style={{borderRadius: 22}}
      TransitionComponent={TransitionSlideUp}>
      <DialogTitle style={{padding: 0}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            {props.plant.name}
          </Typography>
          <IconButton
            color="secondary"
            style={{background: theme.palette.dialogCloseButtonBackground}}
            aria-label="close dialog"
            edge="start"
            onClick={props.handleClose}>
            <CloseIcon
              style={{
                color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
              }}
            />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <Divider style={{margin: 0}}></Divider>
      <DialogContent style={{minWidth: !isMobile ? "1200px" : "200px", paddingTop: "0", paddingBottom: "0"}}>
        <Grid container alignItems="center">
          <Grid item xs={12} style={{height: "450px", overflow: "auto", padding: "0px"}}>
            <TabContext value={value}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable auto tabs tracker">
                <Tab label="Informations" value="1" />
                <Tab label="Paramètres" value="2" />
                <Tab label="Client" value="3" />
                <Tab label="Historique" value="4" />
                <Tab label="Actions" value="5" />
              </Tabs>
              <TabPanel
                value="1"
                style={{
                  paddingTop: "10px",
                  height: "calc(100% - 48px)" /* On enlève 48px correspondant au TabMenu */,
                }}>
                <PlantGeneral plant={props.plant} />
              </TabPanel>
              <TabPanel
                value="2"
                style={{
                  paddingTop: "10px",
                  height: "calc(100% - 48px)" /* On enlève 48px correspondant au TabMenu */,
                }}>
                <PlantSetting plant={props.plant} />
              </TabPanel>
              <TabPanel value="3" style={{padding: "10px"}}>
                <PlantCustomer plant={props.plant} />
              </TabPanel>
              <TabPanel
                value="4"
                style={{
                  paddingTop: "10px",
                  paddingBottom: "0px",
                  height: "calc(100% - 48px)" /* On enlève 48px correspondant au TabMenu */,
                }}>
                <PlantTraces plant={props.plant} />
              </TabPanel>
              <TabPanel value="5" style={{paddingTop: "10px"}}>
                <PlantActions plant={props.plant} />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{paddingRight: "24px", paddingBottom: "24px"}}>
        <Button variant="outlined" color="inherit" onClick={props.handleClose}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
