import authenticatedApi from "../AuthenticatedApi";
import {Agency} from "../../interfaces/Agency";
import {Equipment} from "../../interfaces/Equipment";
import {User} from "../../interfaces/User";
import {ClientSearch} from "../../interfaces/client/ClientSearch";
import {Client} from "../../interfaces/client/Client";
import {CreatePlant, UpdatePlant} from "../../interfaces/plant/Plant";
import {TrackerType} from "../../interfaces/plant/TrackerType";
import {PowerImmersionHeater} from "../../interfaces/PowerImmersionHeater";
import {PowerInverter} from "../../interfaces/PowerInverter";
import {PowerPlant} from "../../interfaces/PowerPlant";
import {SerialNumber} from "../../interfaces/tracker/SerialNumber";
import {Weather} from "../../interfaces/Weather";
import {ListUUIDTrackerAlarmTypeInterface} from "../../app/scada/remote/ListUUIDTrackerAlarmTypeInterface";
import {TrackerWind} from "../../interfaces/TrackerWind";
import {TrackerPosition} from "../../interfaces/TrackerPosition";
import dayjs, {Dayjs} from "dayjs";
import {TrackerAlarm} from "../../interfaces/TrackerAlarm";
import {ImmersionHeaterAlarm} from "../../interfaces/immersion_heater/ImmersionHeaterAlarm";
import {PlantSearch} from "../../interfaces/PlantSearch";
import {PlantStatus} from "../../interfaces/PlantStatus";
import {TrackerStatus} from "../../interfaces/TrackerStatus";
import {ImmersionHeaterStatus} from "../../interfaces/immersion_heater/ImmersionHeaterStatus";
import {MeterStatus} from "../../interfaces/MeterStatus";
import {CurrentAuthorization} from "../../interfaces/CurrentAuthorization";
import {GetAlarmNotViewedBySav} from "../../interfaces/GetAlarmNotViewedBySav";
import {EssStatus} from "../../interfaces/ess/EssStatus";
import UserModel from "../../app/platform/users/model";

import {MapOfPlantStatusesItem} from "../../interfaces/MapOfPlantStatusesItem";
import {CurrentAlarmItem} from "../../interfaces/CurrentAlarmItem";
import {PlantStatusType} from "../../interfaces/PlantStatusTypes";
import {REACT_APP_API_V2_URL} from "../../config";
import {TrackerInternetStatus} from "../../interfaces/TrackerInternetStatus";
import {NumberPvPanel} from "../../interfaces/tracker/NumberPvPanel";
import {SetPvPanel} from "../../interfaces/tracker/SetPvPanel";
import {PvPanelType} from "../../interfaces/tracker/PvPanelType";
import {MeterAlarm} from "../../interfaces/MeterAlarm";
import {PlantAlarm} from "../../interfaces/PlantAlarm";
import {GetAlarmType} from "../../interfaces/alarms/GetAlarmType";
import {AlarmType} from "../../interfaces/AlarmType";
import {StatusCodes} from "http-status-codes";
import Tag from "../../interfaces/Tag";
import {InterventionDate} from "../../interfaces/plant/InterventionDate";
import {SetPlantConsumptionLevel} from "../../interfaces/plant/SetPlantConsumptionLevel";
import {ControlPlantStatus} from "../../interfaces/control/ControlPlantStatus";
import {EssAlarm} from "../../interfaces/ess/EssAlarm";
import {SolarGatewayStatus} from "../../interfaces/solar_gateway/SolarGatewayStatus";
import SolarGatewayAlarm from "../../interfaces/solar_gateway/SolarGatewayAlarm";
import {EssSoC} from "../../interfaces/ess/EssSoC";
import {LegionellosisProtection} from "../../interfaces/immersion_heater/LegionellosisProtection";

const baseUrl = REACT_APP_API_V2_URL;
const powerPlantMinutesEndpoint = "v2/human/power_plant_minutes";
const powerInverterMinutesEndpoint = "v2/human/power_inverter_minutes";
const trackersEndpoint = "v2/human/trackers";
const meterEndpoint = "v2/human/meters";
const plantEndpoint = "v2/human/plants";
const agenciesEndpoint = "v2/human/agencies";
const immersionHeatersEndpoint = "v2/human/immersion_heaters";
const solarGatewaysEndpoint = "v2/human/solar_gateways";
const powerImmersionHeaterMinutesEndpoint = "v2/human/power_immersion_heater_minutes";
const weathersEndpoint = "v2/human/weathers";
const trackersTypes = "v2/human/trackers_types";
const trackerAlarmTypeEndpoint = "v2/human/reference/alarm/tracker_alarm_types";
const restartTrackingByAlarmEndpoint = "v2/human/remote/restart_tracking_by_alarm";
const trackerWindSpeedsEndpoint = "v2/human/tracker_wind_speeds";
const trackerPositionsEndpoint = "v2/human/tracker_positions";
const trackerAlarmsEndpoint = "v2/human/tracker_alarms";
const meterAlarmsEndpoint = "v2/human/meter_alarms";
const plantAlarmsEndpoint = "v2/human/plant_alarms";
const immersionHeaterAlarmsEndpoint = "v2/human/immersion_heater_alarms";
const essAlarmsEndpoint = "v2/human/ess/ess_alarms";
const essStateOfChargeEndpoint = "v2/human/ess/ess_state_of_charge_minutes";
const searchPlantsEndpoint = "v2/human/search_plants";
const searchUsersEndpoint = "v2/human/search_users";
const searchClientsEndpoint = "v2/human/search_clients";
const plantStatusEndpoint = "v2/human/scada/plant_full_statuses";
const trackerStatusEndpoint = "v2/human/scada/tracker_full_statuses";
const immersionHeaterStatusEndpoint = "v2/human/scada/immersion_heater_full_statuses";
const meterStatusEndpoint = "v2/human/scada/meter_full_statuses";
const currentAuthorizationEndpoint = "v2/human/current_authorization";
const getAlarmNotViewedBySavEndpoint = "v2/human/get_alarm_not_viewed_by_sav";
const setAlarmSavStatusAsViewedEndpoint = "v2/human/set_alarm_sav_status_as_viewed";
const restartTrackingEndpoint = "v2/human/remote/restart_tracking";
const startSSHEndpoint = "v2/human/remote/start_ssh";
const stopSSHEndpoint = "v2/human/remote/stop_ssh";
const startFlatEndpoint = "v2/human/remote/start_flat";
const stopFlatEndpoint = "v2/human/remote/stop_flat";
const trackerAlarmEndEndpoint = "v2/human/tracker_alarm_end_collection";
const graphMigrationEndpoint = "v2/human/graph-migration";
const purgeStatusEndpoint = "v2/human/purge_status";
const nominalPowerComputeEndpoint = "v2/human/nominal-power-compute";
const downloadConfiguratorEndpoint = "v2/human/software/configurator";
const essEndpoint = "v2/human/ess/ess";
const essStatusEndpoint = "v2/human/ess/status";
const installatorsEndpoint = "v2/human/user/installators";
const createUserEndpoint = "v2/human/user/create";
const updateUserEndpoint = "v2/human/user/update";
const deleteUserEndpoint = "v2/human/user/delete";
const createClientEndpoint = "v2/human/client/create";
const updateClientEndpoint = "v2/human/client/update";
const deleteClientEndpoint = "v2/human/client/delete";
const detailClientEndpoint = "v2/human/client/detail";
const sendEmailResetPasswordClientEndpoint = "v2/human/client/email_reset_password";
const mapOfPlantStatusesEndpoint = "v2/human/scada/map_of_plant_full_statuses";
const currentAlarmsEndpoint = "v2/human/scada/current_alarms";
const plantStatusTypesEndpoint = "v2/human/scada/plant_status_types";
const createPlantEndpoint = "v2/human/plant/create";
const updatePlantEndpoint = "v2/human/plant/update";
const deletePlantEndpoint = "v2/human/plant/delete";
const detailPlantEndpoint = "v2/human/plant/detail";
const updatePlantInterventionDateEndpoint = "v2/human/set_plant_intervention_date";
const updatePlantConsumptionLevelEndpoint = "v2/human/set_plant_consumption_level";
const deleteTrackerEndpoint = "v2/human/tracker/delete";
const trackerInternetStatusEndpoint = "v2/human/tracker/{trackerId}/internet_status";
const updateTrackerSerialNumberEndpoint = "v2/human/set_tracker_serial_number";
const updateTrackerNumberPvPanelEndpoint = "v2/human/set_tracker_number_pv_panel";
const updateTrackerPvPanelEndpoint = "v2/human/set_tracker_pvpanel";
const updateLegionellosisProtectionEndpoint = "v2/human/immersion_heaters";
const pvPanelTypesEndpoint = "v2/human/reference/tracker/pv_panel_types";
const getAlarmTypesEndpoint = "v2/human/get_alarm_types";
const getPlantTagsEndpoint = "v2/human/tags";
const equipmentsEndpoint = "v2/human/scada/plant/equipments";
const alarmEndsEndpoint = "v2/human/alarm_ends";
const controlPlantStatusEndpoint = "v2/human/control/plants/statuses";

const apiV2 = {
  trackers: async function (plantId: number): Promise<Response> {
    const url = `${baseUrl}/${trackersEndpoint}?plant=${plantId}`;
    return authenticatedApi.get(url);
  },
  meter: async function (plantId: number): Promise<Response> {
    const url = `${baseUrl}/${meterEndpoint}?plant=${plantId}`;
    return authenticatedApi.get(url);
  },
  plant: async function (plantId: number): Promise<Response> {
    const url = `${baseUrl}/${plantEndpoint}/${plantId}`;
    return authenticatedApi.get(url);
  },
  immersionHeaters: async function (plantId: number): Promise<Response> {
    const url = `${baseUrl}/${immersionHeatersEndpoint}?plant=/v2/human/plants/${plantId}`;
    return authenticatedApi.get(url);
  },
  solarGateways: async function (plantId: number): Promise<Response> {
    const url = `${baseUrl}/${solarGatewaysEndpoint}?plant=${plantId}`;
    return authenticatedApi.get(url);
  },
  powerInverter: async function (startDate: Dayjs, endDate: Dayjs, trackerId: number): Promise<Array<PowerInverter>> {
    const url = `${baseUrl}/${powerInverterMinutesEndpoint}?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}&tracker=%2Fv2%2Fhuman%2Ftrackers%2F${trackerId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  powerImmersionHeater: async function (
    startDate: Dayjs,
    endDate: Dayjs,
    immersionHeaterId: string,
  ): Promise<Array<PowerImmersionHeater>> {
    const url = `${baseUrl}/${powerImmersionHeaterMinutesEndpoint}?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}&immersionHeater=%2Fv2%2Fhuman%2Fimmersion_heaters%2F${immersionHeaterId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  powerPlant: async function (startDate: Dayjs, endDate: Dayjs, plantId: number): Promise<Array<PowerPlant>> {
    const url = `${baseUrl}/${powerPlantMinutesEndpoint}?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}&plant=%2Fv2%2Fhuman%2Fplants%2F${plantId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  weather: async function (startDate: Dayjs, endDate: Dayjs, plantId: number): Promise<Array<Weather>> {
    const url = `${baseUrl}/${weathersEndpoint}?begin=${startDate.format("YYYY-MM-DD HH:mm:ss")}&end=${endDate.format("YYYY-MM-DD HH:mm:ss")}&plant=${plantId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  trackerAlarmType: async function (): Promise<Array<AlarmType>> {
    return authenticatedApi
      .get(`${baseUrl}/${trackerAlarmTypeEndpoint}`)
      .then((response) => response.json())
      .then((data) => data);
  },
  restartByAlarm: async function (listSelectedTrackerAlarmType: ListUUIDTrackerAlarmTypeInterface): Promise<Response> {
    const url = `${baseUrl}/${restartTrackingByAlarmEndpoint}`;
    return authenticatedApi.post(url, listSelectedTrackerAlarmType);
  },
  trackerWindSpeed: async function (startDate: Dayjs, endDate: Dayjs, trackerId: number): Promise<Array<TrackerWind>> {
    const url = `${baseUrl}/${trackerWindSpeedsEndpoint}?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}&trackerId=${trackerId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  trackerPositions: async function (
    startDate: Dayjs,
    endDate: Dayjs,
    trackerId: number,
  ): Promise<Array<TrackerPosition>> {
    const url = `${baseUrl}/${trackerPositionsEndpoint}?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}&trackerId=${trackerId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  trackerAlarms: async function (startDate: Dayjs, endDate: Dayjs, trackerId: number): Promise<Array<TrackerAlarm>> {
    const url = `${baseUrl}/${trackerAlarmsEndpoint}?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}&trackerId=${trackerId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  meterAlarms: async function (startDate: Dayjs, endDate: Dayjs, meterId: number): Promise<Array<MeterAlarm>> {
    const url = `${baseUrl}/${meterAlarmsEndpoint}?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}&meterId=${meterId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  plantAlarms: async function (startDate: Dayjs, endDate: Dayjs, plantId: number): Promise<Array<PlantAlarm>> {
    const url = `${baseUrl}/${plantAlarmsEndpoint}?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}&plantId=${plantId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  immersionHeaterAlarms: async function (
    startDate: Dayjs,
    endDate: Dayjs,
    immersionHeaterId: string,
  ): Promise<Array<ImmersionHeaterAlarm>> {
    const url = `${baseUrl}/${immersionHeaterAlarmsEndpoint}?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}&immersionHeaterId=${immersionHeaterId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  essStateOfCharge: async function (startDate: Dayjs, endDate: Dayjs, essId: string): Promise<Array<EssSoC>> {
    const url = `${baseUrl}/${essStateOfChargeEndpoint}?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}&ess=${essId}&page=1`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  searchPlants: async function (search: string, tags: string[] = []): Promise<Array<PlantSearch>> {
    const url = new URL(`${baseUrl}/${searchPlantsEndpoint}`);
    url.searchParams.set("search", search);
    if (tags.length > 0) {
      url.searchParams.set("tags", tags.join(","));
    }
    const response = await authenticatedApi.get(url.href);
    return response.json();
  },
  searchPlantById: async function (id: number): Promise<Array<PlantSearch>> {
    const url = `${baseUrl}/${searchPlantsEndpoint}?id=${id}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  searchUsers: async function (search: string): Promise<Array<User>> {
    const url = `${baseUrl}/${searchUsersEndpoint}?search=${search}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  searchClients: async function (search: string): Promise<Array<ClientSearch>> {
    const url = `${baseUrl}/${searchClientsEndpoint}?search=${search}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  getPlantImage: async function (urlStream: string): Promise<string> {
    const url = `${baseUrl}${urlStream}`;
    return await authenticatedApi.getImage(url);
  },
  plantStatus: async function (plantId: number): Promise<PlantStatus> {
    const url = `${baseUrl}/${plantStatusEndpoint}/${plantId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  trackerStatus: async function (trackerId: number): Promise<TrackerStatus> {
    const url = `${baseUrl}/${trackerStatusEndpoint}/${trackerId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  immersionHeaterStatus: async function (immersionHeaterId: string): Promise<ImmersionHeaterStatus> {
    const url = `${baseUrl}/${immersionHeaterStatusEndpoint}/${immersionHeaterId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  meterStatus: async function (meterId: number): Promise<MeterStatus> {
    const url = `${baseUrl}/${meterStatusEndpoint}/${meterId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  currentAuthorization: async function (): Promise<CurrentAuthorization> {
    const url = `${baseUrl}/${currentAuthorizationEndpoint}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  getAlarmNotViewedBySav: async function (
    plantId?: number,
    begin?: string | null,
    end?: string | null,
    references?: string[] | null,
    tags?: string[] | null,
    interventionDate?: string | null,
    equipments?: string[] | null,
  ): Promise<Array<GetAlarmNotViewedBySav>> {
    let url = `${baseUrl}/${getAlarmNotViewedBySavEndpoint}`;
    const params = [];
    if (plantId) {
      params.push(`plant=${plantId}`);
    }
    if (begin) {
      params.push(`date[after]=${begin}`);
    }
    if (end) {
      params.push(`date[before]=${dayjs(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")}`);
    }
    if (references && references.length > 0) {
      params.push(`references=${references.join(",")}`);
    }
    if (tags && tags.length > 0) {
      params.push(`tags=${tags.join(",")}`);
    }
    if (interventionDate) {
      params.push(`interventionDate=${interventionDate}`);
    }
    if (equipments && equipments.length > 0) {
      params.push(`equipments=${equipments.join(",")}`);
    }
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }
    const response = await authenticatedApi.get(url);
    return response.json();
  },
  setAlarmSavStatusAsViewed: async function (alarmId: string): Promise<Response> {
    const url = `${baseUrl}/${setAlarmSavStatusAsViewedEndpoint}`;
    return authenticatedApi.post(url, {id: alarmId});
  },
  restartTracking: async function (trackerId: number): Promise<Response> {
    const url = `${baseUrl}/${restartTrackingEndpoint}`;
    return authenticatedApi.post(url, {trackerId: trackerId});
  },
  startSSH: async function (trackerId: number): Promise<Response> {
    const url = `${baseUrl}/${startSSHEndpoint}`;
    return authenticatedApi.post(url, {trackerId: trackerId});
  },
  stopSSH: async function (trackerId: number): Promise<Response> {
    const url = `${baseUrl}/${stopSSHEndpoint}`;
    return authenticatedApi.post(url, {trackerId: trackerId});
  },
  startFlat: async function (trackerId: number): Promise<Response> {
    const url = `${baseUrl}/${startFlatEndpoint}`;
    return authenticatedApi.post(url, {trackerId: trackerId});
  },
  stopFlat: async function (trackerId: number): Promise<Response> {
    const url = `${baseUrl}/${stopFlatEndpoint}`;
    return authenticatedApi.post(url, {trackerId: trackerId});
  },
  trackerAlarmEnd: async function (trackerId: number): Promise<Response> {
    const url = `${baseUrl}/${trackerAlarmEndEndpoint}`;
    return authenticatedApi.post(url, {trackerId: trackerId});
  },
  graphMigration: async function (plantId: number, date: string, mode: number): Promise<Response> {
    const url = `${baseUrl}/${graphMigrationEndpoint}`;
    return authenticatedApi.post(url, {
      plant: `/v2/human/plants/${plantId}`,
      date: date,
      mode: mode,
    });
  },
  purgeStatus: async function (plantId: number): Promise<Response> {
    const url = `${baseUrl}/${purgeStatusEndpoint}`;
    return authenticatedApi.post(url, {plant: `/v2/human/plants/${plantId}`});
  },
  nominalPowerCompute: async function (plantId: number): Promise<Response> {
    const url = `${baseUrl}/${nominalPowerComputeEndpoint}`;
    return authenticatedApi.post(url, {plant: `/v2/human/plants/${plantId}`});
  },
  installators: async function (): Promise<Array<User>> {
    const url = `${baseUrl}/${installatorsEndpoint}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  createUser: async function (user: UserModel): Promise<Response> {
    const url = `${baseUrl}/${createUserEndpoint}`;
    return await authenticatedApi.post(url, user);
  },
  updateUser: async function (user: UserModel): Promise<Response> {
    const url = `${baseUrl}/${updateUserEndpoint}`;
    return await authenticatedApi.post(url, user);
  },
  deleteUser: async function (userId: number): Promise<Response> {
    const url = `${baseUrl}/${deleteUserEndpoint}`;
    return await authenticatedApi.post(url, {userId: userId});
  },
  detailClient: async function (clientId: number): Promise<Client> {
    const url = `${baseUrl}/${detailClientEndpoint}/${clientId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  createClient: async function (client: Client): Promise<Response> {
    const url = `${baseUrl}/${createClientEndpoint}`;
    return await authenticatedApi.post(url, client);
  },
  updateClient: async function (client: Client): Promise<Response> {
    const url = `${baseUrl}/${updateClientEndpoint}`;
    return await authenticatedApi.post(url, client);
  },
  deleteClient: async function (clientId: number): Promise<Response> {
    const url = `${baseUrl}/${deleteClientEndpoint}`;
    return await authenticatedApi.post(url, {clientId: clientId});
  },
  sendEmailResetPasswordClient: async function (clientId: number): Promise<Response> {
    const url = `${baseUrl}/${sendEmailResetPasswordClientEndpoint}`;
    return await authenticatedApi.post(url, {clientId: clientId});
  },
  detailPlant: async function (plantId: number): Promise<UpdatePlant> {
    const url = `${baseUrl}/${detailPlantEndpoint}/${plantId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  createPlant: async function (plant: CreatePlant): Promise<Response> {
    const url = `${baseUrl}/${createPlantEndpoint}`;
    return await authenticatedApi.post(url, plant);
  },
  updatePlant: async function (plant: UpdatePlant): Promise<Response> {
    const url = `${baseUrl}/${updatePlantEndpoint}`;
    return await authenticatedApi.post(url, plant);
  },
  updatePlantInterventionDate: async function (interventionDate: InterventionDate): Promise<Response> {
    const url = `${baseUrl}/${updatePlantInterventionDateEndpoint}`;
    return await authenticatedApi.post(url, interventionDate);
  },
  updatePlantConsumptionLevel: async function (interventionDate: SetPlantConsumptionLevel): Promise<Response> {
    const url = `${baseUrl}/${updatePlantConsumptionLevelEndpoint}`;
    return await authenticatedApi.post(url, interventionDate);
  },
  deletePlant: async function (plantId: number): Promise<Response> {
    const url = `${baseUrl}/${deletePlantEndpoint}`;
    return await authenticatedApi.post(url, {plantId: plantId});
  },
  deleteTracker: async function (trackerId: number): Promise<Response> {
    const url = `${baseUrl}/${deleteTrackerEndpoint}`;
    return await authenticatedApi.post(url, {trackerId: trackerId});
  },
  updateTrackerSerialNumber: async function (serialNumber: SerialNumber): Promise<Response> {
    const url = `${baseUrl}/${updateTrackerSerialNumberEndpoint}`;
    return await authenticatedApi.post(url, serialNumber, false);
  },
  updateTrackerNumberPvPanel: async function (numberPvPanel: NumberPvPanel): Promise<Response> {
    const url = `${baseUrl}/${updateTrackerNumberPvPanelEndpoint}`;
    return await authenticatedApi.post(url, numberPvPanel, false);
  },
  updateTrackerPvPanel: async (setPvPanelRequest: SetPvPanel) => {
    return await authenticatedApi.post(`${baseUrl}/${updateTrackerPvPanelEndpoint}`, setPvPanelRequest, false);
  },
  trackerTypes: async function (): Promise<Array<TrackerType>> {
    const url = `${baseUrl}/${trackersTypes}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  updateLegionellosisProtection: async function (legionellosisProtection: LegionellosisProtection): Promise<Response> {
    const url = `${baseUrl}/${updateLegionellosisProtectionEndpoint}/${legionellosisProtection.immersionHeaterId}/legionellosis_protection`;
    return await authenticatedApi.post(
      url,
      {legionellosisProtection: legionellosisProtection.legionellosisProtection},
      false,
    );
  },
  ess: async function (plantId: number): Promise<Response> {
    const url = `${baseUrl}/${essEndpoint}?plant=${plantId}`;
    return authenticatedApi.get(url);
  },
  essStatus: async function (essId: string): Promise<EssStatus> {
    const url = `${baseUrl}/${essStatusEndpoint}/${essId}`;
    const response = await authenticatedApi.get(url);
    return response.json();
  },
  essAlarms: async function (startDate: Dayjs, endDate: Dayjs, essId: string): Promise<Array<EssAlarm>> {
    const url = `${baseUrl}/${essAlarmsEndpoint}?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}&essId=${essId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  agencies: async function (): Promise<Array<Agency>> {
    const url = `${baseUrl}/${agenciesEndpoint}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  equipments: async function (): Promise<Array<Equipment>> {
    const url = `${baseUrl}/${equipmentsEndpoint}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  mapOfPlantStatuses: async function (
    inAgencies: number[],
    interventionDate: string | null,
    notInPlantStatusTypes: string[],
    notSynchronised: boolean,
    startOperationDate: string | null,
    endOperationDate: string | null,
    platform: string | null,
    plantTags: string[],
    equipments: string[],
    checkpointStatuses: string[],
  ): Promise<Array<MapOfPlantStatusesItem>> {
    const inAgenciesFilter = inAgencies.length > 0 ? `inAgencies=${inAgencies.toString()}` : null;
    const interventionDateFilter = interventionDate !== null ? `interventionDate=${interventionDate}` : null;
    const notInPlantStatusTypesFilter =
      notInPlantStatusTypes.length > 0 ? `notInPlantStatusTypes=${notInPlantStatusTypes.toString()}` : null;
    const plantTagsFilter = plantTags.length > 0 ? `tags=${plantTags.toString()}` : null;
    const equipmentsFilter = equipments.length > 0 ? `equipments=${equipments.toString()}` : null;
    const checkpointStatusesFilter =
      checkpointStatuses.length > 0 ? `checkpointStatuses=${checkpointStatuses.toString()}` : null;
    const notSynchronisedFilter = notSynchronised === false ? `notSynchronised=false` : null;
    const startOperationDateFilter =
      startOperationDate !== null ? `date[startOperationDate]=${startOperationDate}` : null;
    const endOperationDateFilter = endOperationDate !== null ? `date[endOperationDate]=${endOperationDate}` : null;
    const platformFilter = platform !== null ? `platform=${platform}` : null;
    const filters = [
      inAgenciesFilter,
      interventionDateFilter,
      notInPlantStatusTypesFilter,
      notSynchronisedFilter,
      startOperationDateFilter,
      endOperationDateFilter,
      platformFilter,
      plantTagsFilter,
      equipmentsFilter,
      checkpointStatusesFilter,
    ]
      .filter((x) => x !== null)
      .join("&");
    const url = `${baseUrl}/${mapOfPlantStatusesEndpoint}?${filters}`;
    const response = await authenticatedApi.get(url);
    return response.json();
  },
  currentAlarms: async function (): Promise<Array<CurrentAlarmItem>> {
    const url = `${baseUrl}/${currentAlarmsEndpoint}`;
    const response = await authenticatedApi.get(url);
    return response.json();
  },
  plantStatusTypes: async function (): Promise<Array<PlantStatusType>> {
    const url = `${baseUrl}/${plantStatusTypesEndpoint}`;
    const response = await authenticatedApi.get(url);
    return response.json();
  },
  trackerInternetStatus: async function (
    startDate: Dayjs,
    endDate: Dayjs,
    trackerId: number,
  ): Promise<Array<TrackerInternetStatus>> {
    const url = `${baseUrl}/${trackerInternetStatusEndpoint.replace("{trackerId}", trackerId.toString())}?begin=${startDate.format("YYYY-MM-DD HH:mm:ss")}&end=${endDate.format("YYYY-MM-DD HH:mm:ss")}`;
    const response = await authenticatedApi.get(url);
    return response.json();
  },
  downloadUrlConfigurator: function (os: string): string {
    const url = `${baseUrl}/${downloadConfiguratorEndpoint}/${os}`;
    return url;
  },
  getPvPanelTypes: async (): Promise<PvPanelType[]> => {
    const response = await authenticatedApi.get(`${baseUrl}/${pvPanelTypesEndpoint}`);
    return response.json();
  },
  getAlarmTypes: async (alarmTypeLevels?: number[]): Promise<GetAlarmType[]> => {
    let url = `${baseUrl}/${getAlarmTypesEndpoint}`;
    const params = [];
    if (alarmTypeLevels && alarmTypeLevels.length > 0) {
      params.push(`levels=${alarmTypeLevels.join(",")}`);
    }
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }
    const response = await authenticatedApi.get(url);
    return response.json();
  },
  getPlantTags: async (search?: string): Promise<Tag[]> => {
    let url = `${baseUrl}/${getPlantTagsEndpoint}`;
    if (search && search !== "") {
      url += `?label=${search}`;
    }
    const response = await authenticatedApi.get(url);
    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred while retrieving plant tags");
    }
    return response.json();
  },
  alarmEnd: async (alarmId: string, end: string): Promise<Response> => {
    const url = `${baseUrl}/${alarmEndsEndpoint}`;
    const payload = {
      id: alarmId,
      end: end,
    };
    return authenticatedApi.post(url, payload);
  },
  controlPlantStatus: async function (plantId: number): Promise<ControlPlantStatus> {
    const url = `${baseUrl}/${controlPlantStatusEndpoint}/${plantId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
  solarGatewayStatus: async function (solarGatewayId: string): Promise<SolarGatewayStatus> {
    const url = `${baseUrl}/v2/human/solar_gateways/${solarGatewayId}/status`;
    const response = await authenticatedApi.get(url);
    return response.json();
  },
  solarGatewayAlarms: async function (
    startDate: Dayjs,
    endDate: Dayjs,
    solarGatewayId: string,
  ): Promise<SolarGatewayAlarm[]> {
    let url = `${baseUrl}/v2/human/solar_gateways/${solarGatewayId}/alarms`;
    url += `?date[after]=${startDate.format("YYYY-MM-DD HH:mm:ss")}&date[before]=${endDate.format("YYYY-MM-DD HH:mm:ss")}`;
    const response = await authenticatedApi.get(url);
    return response.json();
  },
};

export default apiV2;
