import {Ess} from "../../../../../interfaces/ess/Ess";
import AppCollapse from "../AppCollapse";
import BatteryDetail from "./BatteryDetail";

interface propsInterface {
  ess: Ess;
}

export default function EssEquipement(props: propsInterface) {
  return (
    <div>
      <p>
        {props.ess.name} se compose de {props.ess.batteries.length} batteries pour une capacité de{" "}
        {props.ess.ratedCapacity} Wh
      </p>
      {props.ess.batteries.map((battery) => {
        return (
          <AppCollapse small={true} title={"Numéro: " + battery.number + " SN: " + battery.serialNumber}>
            <BatteryDetail battery={battery} />
          </AppCollapse>
        );
      })}
    </div>
  );
}
