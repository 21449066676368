import dayjs from "dayjs";
import {Dayjs} from "dayjs";
import {REACT_APP_API_ENEDIS_URL} from "../../config";

const baseUrl = REACT_APP_API_ENEDIS_URL;

const downloadConsumptionEndpoint = "consumption/download";

const apiEnedis = {
  consumptionUrl: function (pdl: string, beginDate: Dayjs, endDate: Dayjs, plantId?: number): string {
    const strBeginDate = dayjs(beginDate).format("YYYY-MM-DD");
    const strEndDate = dayjs(endDate).format("YYYY-MM-DD");
    const plantQueryParams = plantId !== undefined ? `&plant_id=${plantId}` : "";
    return `${baseUrl}/${downloadConsumptionEndpoint}/${pdl}?start_date=${strBeginDate}&end_date=${strEndDate}${plantQueryParams}`;
  },
};

export default apiEnedis;
