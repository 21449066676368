import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {StatusCodes} from "http-status-codes";

import {ImmersionHeater} from "../../../interfaces/immersion_heater/ImmersionHeater";
import {LegionellosisProtection} from "../../../interfaces/immersion_heater/LegionellosisProtection";

import ToastService from "../../../service/ToastService";

import apiV2 from "../../../service/api/ApiV2";

interface ImmersionHeatersState {
  data: ImmersionHeater[];
  loading: boolean;
  error: string | null;
}

const initialState: ImmersionHeatersState = {
  data: [],
  loading: false,
  error: null,
};

export const updateLegionellosisProtection = createAsyncThunk(
  "immersionHeater/updateLegionellosisProtection",
  async (legionellosisProtection: LegionellosisProtection) => {
    const response = await apiV2.updateLegionellosisProtection(legionellosisProtection);
    return {response, legionellosisProtection};
  },
);

const getImmersionHeaterFromState = (state: ImmersionHeatersState, immersionHeaterId: string): ImmersionHeater => {
  const index = state.data.findIndex((x: ImmersionHeater) => x.id === immersionHeaterId);
  return state.data[index];
};

const immersionHeatersSlice = createSlice({
  name: "ImmersionHeaters",
  initialState,
  reducers: {
    setImmersionHeaters: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateLegionellosisProtection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLegionellosisProtection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Probleme lors de la mise à jour de la protection contre la legionellose";
        ToastService.error(state.error);
      })
      .addCase(updateLegionellosisProtection.fulfilled, (state, action) => {
        state.loading = false;
        const {response, legionellosisProtection} = action.payload;

        if (response.status !== StatusCodes.ACCEPTED) {
          return;
        }
        const immersionHeater = getImmersionHeaterFromState(state, legionellosisProtection.immersionHeaterId);
        immersionHeater.legionellosisProtection = legionellosisProtection.legionellosisProtection;
        ToastService.success("La protection contre la legionellose a été modifié avec succès");
      });
  },
});

export const {setImmersionHeaters} = immersionHeatersSlice.actions;

export default immersionHeatersSlice.reducer;
