import {useState} from "react";
import {Button} from "@mui/material";
import DialogGetEnedisFile from "../../../component/DialogGetEnedisFile";
import {Controller, SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {
  Box,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {HttpRequestHeader} from "antd/es/upload/interface";
import sizeaApi, {DATE_FORMAT_ZULU} from "../../../service/api/ApiSizea";
import Simulation, {ConsumptionFileTypeRef} from "./model";
import {v4 as uuidv4} from "uuid";
import {Upload} from "antd";
import ToastService from "../../../service/ToastService";
import {UploadChangeParam} from "antd/es/upload";
import DownloadIcon from "@mui/icons-material/Download";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {useSearchParams} from "react-router-dom";
import {PlaceholderDialog} from "./component/PlaceholderDialog";
import {SelectTemplate} from "./component/SelectTemplate";
import AutocompleteAddress from "../../../component/form/AutocompleteAddress";
import {convertKilo} from "../../../utils/utils";
import MapAddress from "../../../component/form/MapAddress";
import AppButton from "../../../component/atom/button/AppButton";
import SizeaFormGroup from "./form/SizeaFormGroup";
import SizeaFormEss from "./form/SizeaFormEss";
import SizeaFormImmersionHeaters from "./form/SizeaFormImmersionHeaters";
import SizeaFormTrackers from "./form/SizeaFormTrackers";
import SizeaFormOtherProduction from "./form/SizeaFormOtherProduction";
import GpsInput from "../../../component/atom/form/GpsInput";
import {ProductibleModeEnum} from "./ProductibleModeEnum";
import {SelectProductible} from "./component/SelectProductible";
import {ConsumptionHelperDialog} from "./component/ConsumptionHelperDialog";
import {ProductionHelperDialog} from "./component/ProductionHelperDialog";
import {HotwaterHelperDialog} from "./component/HotwaterHelperDialog";
import apiEnedisAuthorized from "../../../service/KeycloakService";

export default function SizeaForm() {
  const [searchParams] = useSearchParams();

  const getDefaultValues = () => {
    const consumptionFileType = searchParams.get("consumptionFileTypeEnum");
    const consumptionFileTypeEnum: ConsumptionFileTypeRef = Object.values(ConsumptionFileTypeRef).includes(
      consumptionFileType as ConsumptionFileTypeRef,
    )
      ? (consumptionFileType as ConsumptionFileTypeRef)
      : ConsumptionFileTypeRef.SIZEA;

    // Init productionInput avec 1 par défaut si aucun tracker n'est renseigné
    const trackers = searchParams.get("trackers") ? JSON.parse(searchParams.get("trackers") as string) : [[1, null, 0]];
    const trackerInputs = trackers.map((tracker: number[]) => {
      return {
        trackerQuantity: tracker[0],
        trackerTypeId: tracker[1],
        customRatedPower: convertKilo(tracker[2], 3),
      };
    });

    const otherProductionSources = searchParams.get("otherProductionSources")
      ? JSON.parse(searchParams.get("otherProductionSources") as string)
      : [];
    const otherProductionSourceInputs = otherProductionSources.map((otherProductionSource: string[]) => {
      return {
        uuid: uuidv4(),
        productionFileUuid: otherProductionSource[0],
        name: otherProductionSource[1],
      };
    });

    // Init stockage thermique, vide si aucun n'est renseigné
    const hotWaterRequirements = searchParams.get("hotWaterRequirements")
      ? JSON.parse(searchParams.get("hotWaterRequirements") as string)
      : [];
    const waterPointInputs = hotWaterRequirements.map((hotWaterRequirement: (number | string)[]) => {
      return {
        uuid: uuidv4(),
        hotWaterTankType: hotWaterRequirement[0],
        hotWaterRequirementFileUuid: hotWaterRequirement[1],
        volumeCuve: hotWaterRequirement[2],
        targetTemperature: hotWaterRequirement[3],
        immersionHeaterQuantity: hotWaterRequirement[4],
        immersionHeaterTypeId: hotWaterRequirement[5],
      };
    });

    // Init ESS list, vide si aucun n'est renseigné
    const essList = searchParams.get("essList") ? JSON.parse(searchParams.get("essList") as string) : [];
    const essInputs = essList.map((ess: (number | string)[]) => {
      return {
        rescuedReserve: ess[0],
        essTypeId: ess[1],
        converterTypeId: ess[2],
        nbConverter: ess[3],
        batteryTypeId: ess[4],
        nbBattery: ess[5],
      };
    });

    return {
      uuid: uuidv4(),
      plantInput: {
        timezone: "europe/paris",
        consumptionFileUuid: searchParams.get("consumptionFileUuid") ?? null,
        consumptionFileTypeEnum: consumptionFileTypeEnum,
        startDate: searchParams.get("startDate") ?? null,
        endDate: searchParams.get("endDate") ?? null,
        name: searchParams.get("name") ?? "",
        address: searchParams.get("address") ?? "",
        latitude: searchParams.get("latitude") ? parseFloat(searchParams.get("latitude") as string) : undefined,
        longitude: searchParams.get("longitude") ? parseFloat(searchParams.get("longitude") as string) : undefined,
        mapImageFileUuid: null,
      },
      isCustom: searchParams.get("isCustom") ? searchParams.get("isCustom") === "1" : false,
      template: searchParams.get("template") ?? null,
      productibleModeEnum: ProductibleModeEnum.API_SOLAR_PRODUCTION,
      customTemplate: searchParams.get("customTemplate") ?? null,
      productionInput: {
        isRestricted: searchParams.get("isRestricted") ? searchParams.get("isRestricted") === "1" : undefined,
        restrictedPower: searchParams.get("restrictedPower")
          ? parseInt(searchParams.get("restrictedPower") as string)
          : 0,
        trackerInputs: trackerInputs,
        otherProductionSourceInputs: otherProductionSourceInputs,
      },
      waterPointInputs: waterPointInputs,
      essInputs: essInputs,
      simulateEconomy: searchParams.get("simulateEconomy") ? searchParams.get("simulateEconomy") === "1" : false,
      buyPriceFileUuid: searchParams.get("buyPriceFileUuid") ?? null,
      simulateProfit: searchParams.get("simulateProfit") ? searchParams.get("simulateProfit") === "1" : false,
      sellPriceFileUuid: searchParams.get("sellPriceFileUuid") ?? null,
    };
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    clearErrors,
    formState: {errors},
  } = useForm<Simulation>({
    defaultValues: getDefaultValues(),
  });

  const {
    fields: fieldsTrackers,
    append: appendTrackers,
    remove: removeTrackers,
  } = useFieldArray({
    control,
    name: "productionInput.trackerInputs",
  });

  const watchFieldTrackersArray = watch("productionInput.trackerInputs");

  const {
    fields: fieldsOtherProductionSources,
    append: appendOtherProductionSources,
    remove: removeOtherProductionSources,
  } = useFieldArray({
    control,
    name: "productionInput.otherProductionSourceInputs",
  });

  const watchFieldOtherProductionSourcesArray = watch("productionInput.otherProductionSourceInputs");

  const {
    fields: fieldsHotWaterRequirements,
    append: appendHotWaterRequirements,
    remove: removeHotWaterRequirements,
  } = useFieldArray({
    control,
    name: "waterPointInputs",
  });
  const watchFieldHotWaterRequirementsArray = watch("waterPointInputs");

  const {
    fields: fieldsEssList,
    append: appendEssList,
    remove: removeEssList,
  } = useFieldArray({
    control,
    name: "essInputs",
  });
  const watchFieldEssArray = watch("essInputs");
  const watchIsRestricted = watch("productionInput.isRestricted");

  const uploadOnChange = function (param: UploadChangeParam) {
    if (param.file === undefined) return;
    if (param.file.error === undefined) return;
    if (param.file.response === undefined) return;

    // Erreur générique
    let violation = param.file.response.detail;

    // Sinon on affiche l'erreur API PLATFORM
    if (param.file.response["hydra:description"] !== undefined) {
      violation = param.file.response["hydra:description"];
    }

    ToastService.error(violation);
  };

  const uploadTemplateOnChange = function (param: UploadChangeParam) {
    uploadOnChange(param);

    if (param.file?.response) {
      const response = param.file?.response;
      // filename généré par le serveur
      const filename = response.fileName;
      setValue("customTemplate", filename);
      clearErrors("customTemplate");
    }
  };

  const onSubmit: SubmitHandler<Simulation> = (data) => {
    // chaque simulation a son propre id
    data.uuid = uuidv4();

    data.isCustom = watch(`template`) === "custom";
    if (data.isCustom) {
      data.template = data.customTemplate;
    }

    if (data.simulateEconomy && data.buyPriceFileUuid === null) {
      ToastService.error("Veuillez téléverser un fichier de prix d'achat");
      return;
    }
    if (data.simulateProfit && data.sellPriceFileUuid === null) {
      ToastService.error("Veuillez téléverser un fichier de prix de revente");
      return;
    }

    data.productionInput.trackerInputs = data.productionInput.trackerInputs.map((trackerInput) => {
      trackerInput.customRatedPower = trackerInput.customRatedPower * 1000; // repasser ici en Watts pour le serveur
      return trackerInput;
    });

    sizeaApi.simulate(data).then(() => {
      ToastService.success(
        "Votre demande a été prise en compte, la simulation vous sera envoyée par mail dans les 30 minutes à venir",
      );
    });
  };

  const handlePositionChange = ({lat, lng}: {lat: number; lng: number}) => {
    setValue("plantInput.latitude", lat);
    setValue("plantInput.longitude", lng);
  };

  const handleMapCapture = (mapCanvas: HTMLCanvasElement) => {
    const mapImageUuid = uuidv4();
    const mapImageFilename = mapImageUuid + ".png";

    mapCanvas.toBlob(function (blob) {
      if (!blob) {
        return;
      }

      sizeaApi.uploadMapImage(mapImageFilename, blob).then(() => {
        setValue(`plantInput.mapImageFileUuid`, mapImageUuid);
      });
    }, "image/png");
  };

  const [enedisFileDialogOpen, setEnedisFileDialogOpen] = useState(false);

  const handleGetEnedisFile = () => {
    setEnedisFileDialogOpen(true);
  };

  return (
    <Card
      style={{margin: "auto", borderRadius: "8px", boxShadow: "none"}}
      sx={{width: {xs: "100%", md: "80%", lg: "65%"}}}>
      <CardContent>
        <Grid container justifyContent={"center"} direction={"row"}>
          <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}}>
            <FormControl fullWidth>
              <Stack spacing={2}>
                <FormGroup>
                  <TextField
                    required
                    error={!!errors.plantInput?.name}
                    label="Nom installation"
                    {...register("plantInput.name", {required: true})}
                  />
                </FormGroup>
                <Divider textAlign="left">Implantation</Divider>
                <FormControl style={{marginBottom: "0px"}} fullWidth>
                  <Controller
                    name={`plantInput.address`}
                    control={control}
                    render={({field}) => (
                      <AutocompleteAddress
                        {...field}
                        label="Adresse"
                        required={true}
                        preloadvalue={watch(`plantInput.address`)}
                        register={register}
                        options={{required: true}}
                        setValue={setValue}
                        trigger={trigger}
                        address={`plantInput.address`}
                        latitude={`plantInput.latitude`}
                        longitude={`plantInput.longitude`}
                        error={!!errors.plantInput?.address}
                        latLngPrecision={3}
                        doNotLoadGoogleMapScript
                      />
                    )}
                  />
                </FormControl>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <FormGroup>
                    <GpsInput
                      label="Latitude"
                      error={!!errors.plantInput?.latitude}
                      name="plantInput.latitude"
                      onChange={(value: number) => {
                        setValue("plantInput.latitude", value);
                      }}
                      value={watch("plantInput.latitude") || ""}
                    />
                  </FormGroup>
                  <FormGroup>
                    <GpsInput
                      label="Longitude"
                      error={!!errors.plantInput?.longitude}
                      name="plantInput.longitude"
                      onChange={(value: number) => {
                        setValue("plantInput.longitude", value);
                      }}
                      value={watch("plantInput.longitude") || ""}
                    />
                  </FormGroup>
                </Stack>

                <FormControl>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <MapAddress
                      latitude={watch("plantInput.latitude")}
                      longitude={watch("plantInput.longitude")}
                      onChange={handlePositionChange}
                      precision={3}
                      mapWidth="550px"
                      mapHeight="331px"
                      onCapture={handleMapCapture}
                    />
                  </Box>
                </FormControl>

                <Divider textAlign="left">
                  Consommation{" "}
                  <AppButton
                    id="consumption-example-download"
                    title="Exemple format Sizea"
                    onClick={() => {
                      sizeaApi.getExampleFile("consumptions-example");
                    }}
                    style="secondary"
                    icon={<DownloadIcon />}
                  />
                  <ConsumptionHelperDialog />
                </Divider>

                {apiEnedisAuthorized() && (
                  <>
                    <Button
                      onClick={handleGetEnedisFile}
                      sx={{
                        textDecoration: "underline",
                        textTransform: "none",
                        padding: 0,
                        alignSelf: "flex-start",
                      }}>
                      Obtenir le fichier Enedis
                    </Button>
                    <DialogGetEnedisFile
                      open={enedisFileDialogOpen}
                      handleClose={() => setEnedisFileDialogOpen(false)}
                      title="Données de consommation du client"
                      actionLabel="Télécharger"
                    />
                  </>
                )}

                <FormControl>
                  <FormLabel required id="file-type-label">
                    Type de fichier
                  </FormLabel>
                  <Controller
                    rules={{required: true}}
                    control={control}
                    name={`plantInput.consumptionFileTypeEnum`}
                    render={({field}) => (
                      <RadioGroup
                        row
                        {...field}
                        id={`consumptionFileTypeEnum-select`}
                        style={{marginLeft: 15}}
                        defaultValue={watch("plantInput.consumptionFileTypeEnum")}>
                        <FormControlLabel
                          value={ConsumptionFileTypeRef.ENEDIS}
                          control={<Radio />}
                          label="Courbe de Charge Brut"
                        />
                        <FormControlLabel
                          value={ConsumptionFileTypeRef.SIZEA}
                          control={<Radio />}
                          label="Courbe de Charge Modifiée / PM"
                        />
                        <FormControlLabel
                          value={ConsumptionFileTypeRef.NO_CONSUMPTION}
                          control={<Radio />}
                          label="Pas de consommation"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>

                {watch(`plantInput.consumptionFileTypeEnum`) !== ConsumptionFileTypeRef.SIZEA && ( // pour ENEDIS et NO_CONSUMPTION, les dates de début et fin sont obligatoires. Pour SIZEA, on ne mentionne pas de dates du tout
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <FormGroup>
                      <Controller
                        control={control}
                        name={`plantInput.startDate`}
                        rules={{
                          required: watch(`plantInput.consumptionFileTypeEnum`) === ConsumptionFileTypeRef.ENEDIS,
                        }}
                        render={({field: {ref, onBlur, name, onChange, ...field}, fieldState}) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
                            <DatePicker
                              {...field}
                              inputRef={ref}
                              label="Du"
                              onChange={(event) => {
                                if (null !== event) {
                                  onChange(dayjs(event).utc().format(DATE_FORMAT_ZULU));
                                }
                              }}
                              renderInput={(inputProps) => (
                                <TextField
                                  {...inputProps}
                                  onBlur={onBlur}
                                  name={name}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Controller
                        control={control}
                        name={`plantInput.endDate`}
                        rules={{
                          required: watch(`plantInput.consumptionFileTypeEnum`) === ConsumptionFileTypeRef.ENEDIS,
                        }}
                        render={({field: {ref, onBlur, name, onChange, ...field}, fieldState}) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
                            <DatePicker
                              {...field}
                              inputRef={ref}
                              label="Au"
                              onChange={(event) => {
                                if (null !== event) {
                                  onChange(dayjs(event).utc().format(DATE_FORMAT_ZULU));
                                }
                              }}
                              renderInput={(inputProps) => (
                                <TextField
                                  {...inputProps}
                                  onBlur={onBlur}
                                  name={name}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </FormGroup>
                  </Stack>
                )}

                {watch(`plantInput.consumptionFileTypeEnum`) !== ConsumptionFileTypeRef.NO_CONSUMPTION && (
                  <Controller
                    control={control}
                    name={`plantInput.consumptionFileUuid`}
                    rules={{
                      required: {
                        value: watch(`plantInput.consumptionFileTypeEnum`) !== ConsumptionFileTypeRef.NO_CONSUMPTION,
                        message: "Le fichier de consommation est requis.",
                      },
                    }}
                    render={({fieldState}) => (
                      <FormControl error={!!fieldState.error}>
                        <Upload
                          name="file"
                          data={{
                            identifier: "consumption-" + watch("plantInput.consumptionFileUuid"),
                            timezone: watch("plantInput.timezone"),
                            consumptionFileTypeEnum: watch("plantInput.consumptionFileTypeEnum"),
                            startDate: watch("plantInput.startDate"),
                            endDate: watch("plantInput.endDate"),
                          }}
                          headers={sizeaApi.getUploadHeaders() as unknown as HttpRequestHeader}
                          onChange={uploadOnChange}
                          onRemove={() => {
                            setValue("plantInput.consumptionFileUuid", null);
                          }}
                          beforeUpload={() => {
                            setValue("plantInput.consumptionFileUuid", uuidv4());
                            clearErrors("plantInput.consumptionFileUuid");
                          }}
                          accept=".xlsx, .csv"
                          action={sizeaApi.getUploadUrl()}
                          listType="picture-card"
                          showUploadList={true}
                          maxCount={1}
                          style={{fontFamily: "Montserrat"}}
                          defaultFileList={
                            watch("plantInput.consumptionFileUuid")
                              ? [
                                  {
                                    uid: watch("plantInput.consumptionFileUuid") || "",
                                    name: "Fichier de consommation indiqué par l'URL",
                                  },
                                ]
                              : []
                          }>
                          Déposez vos fichiers ici
                        </Upload>
                        <FormHelperText className="Mui-required">{fieldState.error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                )}
                <Divider textAlign="left">
                  Production
                  <AppButton
                    id="production-example-download"
                    title="Exemple fichier de production"
                    onClick={() => {
                      sizeaApi.getExampleFile("production-example");
                    }}
                    style="secondary"
                    icon={<DownloadIcon />}
                  />
                  <ProductionHelperDialog />
                </Divider>

                {watch(`plantInput.consumptionFileTypeEnum`) !== ConsumptionFileTypeRef.NO_CONSUMPTION && (
                  <Grid container spacing={1} alignItems="left">
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            defaultChecked={watch("productionInput.isRestricted")}
                            {...register("productionInput.isRestricted")}
                          />
                        }
                        label="Bridage"
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      {watchIsRestricted && (
                        <TextField
                          type="number"
                          inputProps={{step: 100}}
                          error={!!errors.productionInput?.restrictedPower}
                          label="Valeur de bridage (W)"
                          helperText={
                            errors.productionInput?.restrictedPower
                              ? errors.productionInput.restrictedPower.message
                              : ""
                          }
                          {...register("productionInput.restrictedPower", {
                            required: false,
                            valueAsNumber: true,
                            min: {value: 0, message: "La valeur doit être supérieure ou égale à 0."},
                            max: {value: 999999, message: "La valeur doit être inférieure ou égale à 999 999."},
                          })}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}

                <SizeaFormGroup>
                  <SizeaFormTrackers
                    watchFieldArray={watchFieldTrackersArray}
                    control={control}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                    fields={fieldsTrackers}
                    append={appendTrackers}
                    remove={removeTrackers}
                  />
                </SizeaFormGroup>

                <SizeaFormGroup>
                  <SizeaFormOtherProduction
                    watchFieldArray={watchFieldOtherProductionSourcesArray}
                    control={control}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                    fields={fieldsOtherProductionSources}
                    append={appendOtherProductionSources}
                    remove={removeOtherProductionSources}
                    clearErrors={clearErrors}
                  />
                </SizeaFormGroup>

                <Divider textAlign="left">
                  Stockage
                  <AppButton
                    id="hot-water-requirements-example-download"
                    title="Exemple format Sizea"
                    onClick={() => {
                      sizeaApi.getExampleFile("hot-water-requirements-example");
                    }}
                    style="secondary"
                    icon={<DownloadIcon />}
                  />
                  <HotwaterHelperDialog />
                </Divider>

                {watch(`plantInput.consumptionFileTypeEnum`) !== ConsumptionFileTypeRef.NO_CONSUMPTION && (
                  <SizeaFormGroup>
                    <SizeaFormImmersionHeaters
                      watchFieldArray={watchFieldHotWaterRequirementsArray}
                      control={control}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      errors={errors}
                      fields={fieldsHotWaterRequirements}
                      append={appendHotWaterRequirements}
                      remove={removeHotWaterRequirements}
                      clearErrors={clearErrors}
                    />
                  </SizeaFormGroup>
                )}
                <SizeaFormGroup>
                  <SizeaFormEss
                    watchFieldArray={watchFieldEssArray}
                    control={control}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                    fields={fieldsEssList}
                    append={appendEssList}
                    remove={removeEssList}
                  />
                </SizeaFormGroup>

                <Divider textAlign="left">Tarification</Divider>

                <FormControlLabel
                  control={<Switch defaultChecked={watch("simulateEconomy")} {...register("simulateEconomy")} />}
                  label="Simulation de l'économie financière de l'autoconsommation"
                  labelPlacement="end"
                />
                {watch("simulateEconomy") && (
                  <SizeaFormGroup>
                    <Box>
                      Estimez le montant économisé grâce à l'énergie solaire autoconsommée, qui réduit ainsi la quantité
                      d'énergie soutirée du réseau éléctrique.
                    </Box>
                    <Box>
                      Pour cela, importez un fichier de tarification qui définit le{" "}
                      <strong>coût de l'énergie à l'achat</strong>, heure par heure.
                    </Box>
                    <Box>
                      Cela vous permet de simuler aussi bien un contrat fixe en heures pleines/creuses qu'un tarif
                      dynamique indexé spot.
                    </Box>
                    <Controller
                      control={control}
                      name={`buyPriceFileUuid`}
                      rules={{
                        required: {
                          value: watch(`simulateEconomy`),
                          message: "Le fichier de prix d'achat est requis.",
                        },
                      }}
                      render={({fieldState}) => (
                        <FormControl error={!!fieldState.error}>
                          <Upload
                            className="buyprice-upload"
                            name="file"
                            data={{
                              identifier: "buyprice-" + watch("buyPriceFileUuid"),
                              timezone: watch("plantInput.timezone"),
                              startDate: watch("plantInput.startDate"),
                              endDate: watch("plantInput.endDate"),
                            }}
                            headers={sizeaApi.getUploadHeaders() as unknown as HttpRequestHeader}
                            onChange={uploadOnChange}
                            onRemove={() => {
                              setValue("buyPriceFileUuid", null);
                            }}
                            beforeUpload={() => {
                              setValue("buyPriceFileUuid", uuidv4());
                              clearErrors("buyPriceFileUuid");
                            }}
                            accept=".xlsx"
                            action={sizeaApi.getUploadUrl()}
                            listType="picture-card"
                            showUploadList={true}
                            maxCount={1}
                            style={{fontFamily: "Montserrat"}}
                            defaultFileList={
                              watch("buyPriceFileUuid")
                                ? [
                                    {
                                      uid: watch("buyPriceFileUuid") || "",
                                      name: "Fichier de prix d'achat indiqué par l'URL",
                                    },
                                  ]
                                : []
                            }>
                            Déposez votre fichier ici
                          </Upload>
                          <FormHelperText className="Mui-required">{fieldState.error?.message}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </SizeaFormGroup>
                )}

                <FormControlLabel
                  control={<Switch defaultChecked={watch("simulateProfit")} {...register("simulateProfit")} />}
                  label="Simulation de la revente du surplus"
                  labelPlacement="end"
                />
                {watch("simulateProfit") && (
                  <SizeaFormGroup>
                    <Box>
                      Estimez le montant que peut rapporter la revente du surplus solaire réinjecté sur le réseau.
                    </Box>
                    <Box>
                      Pour cela, importez un fichier de tarification qui définit le{" "}
                      <strong>coût de l'énergie à la revente</strong>, heure par heure.
                    </Box>
                    <Box>Cela vous permet de simuler aussi bien un contrat de revente fixe qu'un tarif dynamique.</Box>
                    <Controller
                      control={control}
                      name={`sellPriceFileUuid`}
                      rules={{
                        required: {
                          value: watch(`simulateProfit`),
                          message: "Le fichier de prix de vente est requis.",
                        },
                      }}
                      render={({fieldState}) => (
                        <FormControl error={!!fieldState.error}>
                          <Upload
                            className="sellprice-upload"
                            name="file"
                            data={{
                              identifier: "sellprice-" + watch("sellPriceFileUuid"),
                              timezone: watch("plantInput.timezone"),
                              startDate: watch("plantInput.startDate"),
                              endDate: watch("plantInput.endDate"),
                            }}
                            headers={sizeaApi.getUploadHeaders() as unknown as HttpRequestHeader}
                            onChange={uploadOnChange}
                            onRemove={() => {
                              setValue("sellPriceFileUuid", null);
                            }}
                            beforeUpload={() => {
                              setValue("sellPriceFileUuid", uuidv4());
                              clearErrors("sellPriceFileUuid");
                            }}
                            accept=".xlsx"
                            action={sizeaApi.getUploadUrl()}
                            listType="picture-card"
                            showUploadList={true}
                            maxCount={1}
                            style={{fontFamily: "Montserrat"}}
                            defaultFileList={
                              watch("sellPriceFileUuid")
                                ? [
                                    {
                                      uid: watch("sellPriceFileUuid") || "",
                                      name: "Fichier de prix de vente indiqué par l'URL",
                                    },
                                  ]
                                : []
                            }>
                            Déposez votre fichier ici
                          </Upload>
                          <FormHelperText className="Mui-required">{fieldState.error?.message}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </SizeaFormGroup>
                )}

                <Divider textAlign="left">
                  Modèle
                  <PlaceholderDialog />
                </Divider>
                <div id="section-template">
                  <SelectTemplate
                    labelId="template-id"
                    label={"Template"} // masqué, mais prépare une place quand le label est shrinked, et évite que la ligne passe à travers du label
                    sx={{minWidth: 200}}
                    name={`template`}
                    error={!!errors.template}
                    control={control}
                  />
                  {watch(`template`) === "custom" && (
                    <Controller
                      control={control}
                      name={`customTemplate`}
                      rules={{
                        required: {
                          value: watch(`template`) === "custom",
                          message: "Le fichier modèle est requis.",
                        },
                      }}
                      render={({fieldState}) => (
                        <FormControl error={!!fieldState.error}>
                          <Upload
                            name="file"
                            headers={sizeaApi.getUploadHeaders() as unknown as HttpRequestHeader}
                            onChange={uploadTemplateOnChange}
                            accept=".docx"
                            action={sizeaApi.getUploadCustomTemplateUrl()}
                            listType="picture-card"
                            showUploadList={true}
                            maxCount={1}
                            style={{fontFamily: "Montserrat"}}
                            onRemove={() => {
                              setValue("customTemplate", null);
                            }}
                            defaultFileList={
                              watch("customTemplate")
                                ? [
                                    {
                                      uid: watch("customTemplate") || "",
                                      name: "Fichier modèle indiqué par l'URL",
                                    },
                                  ]
                                : []
                            }>
                            Déposez votre fichier ici
                          </Upload>
                          <FormHelperText className="Mui-required">{fieldState.error?.message}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  )}
                </div>
                <Divider textAlign="left">Productible</Divider>
                <SelectProductible name={`productibleModeEnum`} control={control} />
                <Divider></Divider>
                <Grid container justifyContent={"center"} direction={"row"}>
                  <AppButton style="primary" label="Valider" type="submit" />
                </Grid>
              </Stack>
            </FormControl>
          </form>
        </Grid>
      </CardContent>
    </Card>
  );
}
