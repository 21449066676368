import keycloak from "../../Keycloak";

const DownloadFileXMLHttp = {
  download: function (url: string, filename: string): Promise<XMLHttpRequest> {
    let blob: Blob;
    const xmlHTTP = new XMLHttpRequest();

    xmlHTTP.open("GET", url, true);
    xmlHTTP.setRequestHeader("Authorization", "Bearer " + keycloak.token);
    xmlHTTP.setRequestHeader("Keep-Alive", "timeout=100, max=1000");

    xmlHTTP.responseType = "arraybuffer";
    xmlHTTP.onload = function () {
      blob = new Blob([this.response]);
    };
    xmlHTTP.onloadend = () => {
      let url = "";
      const element = document.createElement("a");
      document.body.appendChild(element);
      url = window.URL.createObjectURL(blob);
      element.href = url;
      element.download = filename;
      element.click();
      window.URL.revokeObjectURL(url);
    };
    xmlHTTP.onerror = (error) => {
      console.log(error);
    };
    xmlHTTP.send();
    return new Promise((resolve) => {
      resolve(xmlHTTP);
    });
  },
};

export default DownloadFileXMLHttp;
