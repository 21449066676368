import {Divider} from "@mui/material";
import CardRow from "../CardRow";
import {formatDateTime} from "../../../../../service/format/FormatDateTime";
import {ImmersionHeater} from "../../../../../interfaces/immersion_heater/ImmersionHeater";

interface propsInterface {
  immersionHeater: ImmersionHeater;
}

export default function ImmersionHeaterGeneral(props: propsInterface) {
  return (
    <div>
      <h4>Général</h4>

      <CardRow label="Identifiant">{props.immersionHeater.id}</CardRow>

      <CardRow label="Référence">{props.immersionHeater.immersionHeaterTypeEntity.label}</CardRow>

      <CardRow label="Puissance">{props.immersionHeater.immersionHeaterTypeEntity.ratedPower} W</CardRow>

      <CardRow label="Mise en route">{formatDateTime(props.immersionHeater.operationDate)}</CardRow>

      <div style={{marginTop: "20px"}}>
        <Divider></Divider>
      </div>

      <h4>Logiciel</h4>

      <CardRow label="Firmware version">{props.immersionHeater.firmwareVersion}</CardRow>
    </div>
  );
}
