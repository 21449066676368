import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import {useDispatch, useSelector} from "react-redux";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {SerialNumber} from "../../../../../../interfaces/tracker/SerialNumber";

import {AppDispatch, RootState} from "../../../../../../store/store";
import {updateTrackerSerialNumber} from "../../../../../../store/scada/tracker/trackers.store";
import ImperativeFormHandle from "../../../../../../interfaces/ImperativeFormHandle";

type SerialNumberProps = {
  index: number;
  onSuccess: () => void;
};

const SerialNumberForm = forwardRef<ImperativeFormHandle, SerialNumberProps>((props: SerialNumberProps, ref) => {
  const trackers = useSelector((state: RootState) => state.trackers);
  const dispatch = useDispatch<AppDispatch>();

  const {index} = props;

  const {
    control,
    register,
    handleSubmit,
    trigger,
    setValue,
    formState: {errors},
  } = useForm<SerialNumber>({
    defaultValues: {
      serialNumber: "",
      trackerId: 0,
    },
  });

  const onSubmit: SubmitHandler<SerialNumber> = async (data) => {
    await dispatch(updateTrackerSerialNumber(data));
    props.onSuccess();
  };

  //permet d appeler le submit form depuis le composant parent
  useImperativeHandle(ref, () => ({
    submitForm() {
      trigger();
      handleSubmit(onSubmit)();
    },
  }));

  useEffect(() => {
    setValue("serialNumber", trackers.data[index].serialNumber);
    setValue("trackerId", trackers.data[index].id);
  }, [props.index]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete={"off"}>
        <Box component="div" style={{marginTop: "0px", marginBottom: "-20px", padding: "0px"}}>
          <Controller
            name="serialNumber"
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                id="SerialNumberTextField"
                type="text"
                variant="standard"
                size="small"
                color="primary"
                style={{margin: "0px", padding: "0px", width: "90%"}}
                required
                error={!!errors.serialNumber}
                {...register("serialNumber", {
                  required: "Le numéro de serie est obligatoire",
                  minLength: {
                    value: 3,
                    message: "Le numéro de serie doit contenir au moins 3 caractères",
                  },
                })}
                fullWidth
                helperText={errors.serialNumber ? errors.serialNumber.message : null}
              />
            )}
          />
        </Box>
      </form>
    </>
  );
});

export default SerialNumberForm;
