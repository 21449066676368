import {ConsumptionLevelEnum} from "../../interfaces/plant/ConsumptionLevelEnum";

type ConsumptionLevelData = {
  label: string;
  value: ConsumptionLevelEnum;
};

export const formatConsumptionLevel = (data: string | null): string => {
  switch (data) {
    case ConsumptionLevelEnum.DOWNSTREAM_MINUS:
      return "Bidirectionnel -";
    case ConsumptionLevelEnum.DOWNSTREAM_PLUS:
      return "Bidirectionnel +";
    case ConsumptionLevelEnum.UPSTREAM_MINUS:
      return "Monodirectionnel -";
    case ConsumptionLevelEnum.UPSTREAM_PLUS:
      return "Monodirectionnel +";
    default:
      return "N/A";
  }
};

export const getListConsumptionLevel = (): ConsumptionLevelData[] => {
  return [
    {
      label: "Bidirectionel +",
      value: ConsumptionLevelEnum.DOWNSTREAM_PLUS,
    },
    {
      label: "Bidirectionel -",
      value: ConsumptionLevelEnum.DOWNSTREAM_MINUS,
    },
    {
      label: "Monodirectionel +",
      value: ConsumptionLevelEnum.UPSTREAM_PLUS,
    },
    {
      label: "Monodirectionel -",
      value: ConsumptionLevelEnum.UPSTREAM_MINUS,
    },
  ];
};
