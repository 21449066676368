import React, {useRef, useState} from "react";

import {Divider} from "@mui/material";

import CardRow from "../CardRow";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/store";
import {formatDateTime} from "../../../../../service/format/FormatDateTime";

import SerialNumberForm from "./form/SerialNumberForm";
import ImperativeFormHandle from "../../../../../interfaces/ImperativeFormHandle";

interface propsInterface {
  index: number;
}

export function TrackerGeneral(props: propsInterface) {
  const [isSerialNumberEditing, setIsSerialNumberEditing] = useState(false);
  const trackers = useSelector((state: RootState) => state.trackers);
  const formSerialNumberRef = useRef<ImperativeFormHandle>(null);

  const handleAction = () => {
    if (formSerialNumberRef && formSerialNumberRef.current) {
      formSerialNumberRef.current.submitForm();
    }
  };

  return (
    <div>
      <h4>Général</h4>

      <CardRow isCopyEnable={true} label="Identifiant">
        {trackers.data[props.index].id}
      </CardRow>

      <CardRow
        label="Numéro de série"
        isEditable={true}
        isEditing={isSerialNumberEditing}
        isLoading={trackers.loading}
        onEditChange={(isEditing) => setIsSerialNumberEditing(isEditing)}
        children={trackers.data[props.index].serialNumber}
        childrenEditValidate={handleAction}
        childrenEdit={
          <SerialNumberForm
            ref={formSerialNumberRef}
            index={props.index}
            onSuccess={() => setIsSerialNumberEditing(false)}
          />
        }></CardRow>

      <CardRow label="Type de tracker">{trackers.data[props.index].trackerTypeLabel}</CardRow>
      <CardRow label="Taille">{trackers.data[props.index].size} m2</CardRow>
      <CardRow label="Mise en route">{formatDateTime(trackers.data[props.index].operationDate)}</CardRow>

      <div style={{marginTop: "20px"}}>
        <Divider></Divider>
      </div>

      <h4>Logiciel</h4>

      <CardRow label="Okwind App">{trackers.data[props.index].okwindAppVersion}</CardRow>
      <CardRow label="Kernel">{trackers.data[props.index].kernelVersion}</CardRow>
      {trackers.data[props.index].jfrogDeviceUuid && (
        <CardRow isCopyEnable={true} label="JFrog UUID">
          {trackers.data[props.index].jfrogDeviceUuid}
        </CardRow>
      )}
      {trackers.data[props.index].jfrogDeviceId && (
        <CardRow isCopyEnable={true} label="JFrog identifiant">
          {trackers.data[props.index].jfrogDeviceId}
        </CardRow>
      )}
    </div>
  );
}
