import {ForwardedRef, useEffect, useMemo, useRef, useState} from "react";
import {ChartData, ChartDataset, ChartOptions, Point} from "chart.js";
import {Dayjs} from "dayjs";
import {useTheme} from "@mui/material";
import {forkJoin} from "rxjs";
import {formatXLabels, generateDates} from "../app/scada/plant/DateService";
import {
  borderDashBasedOnDisconnectionAlarms,
  createAlarmTimeserie,
  createYLabels,
  eventLevelColor,
  getAlarmClicked,
  getCurrentAlarmIndexesFromReference,
  mappingAlarmsToYvalues,
} from "../app/scada/plant/AlarmService";
import GraphResizeService from "../service/GraphResizeService";
import {AlarmInfo} from "../interfaces/AlarmInfo";
import {Plant} from "../interfaces/Plant";
import {mergeObjectsRecursive} from "../utils/utils";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";

type UseEventChartProps = {
  plant: Plant;
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
  fetchAlarms: (startDate: Dayjs, endDate: Dayjs, id: string) => Promise<any[]>;
  id: string;
  title: string;
};

type UseEventChartReturn = {
  chartData: ChartData<"line">;
  options: ChartOptions<"line">;
  openDialog: (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => void;
  ref: React.RefObject<HTMLDivElement>;
  chartRef: ForwardedRef<ChartJSOrUndefined<"line", (number | Point | null)[], unknown>> | undefined;
  width: number;
  chartHeight: number;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
  openAlarmDialog: boolean;
  setOpenAlarmDialog: React.Dispatch<React.SetStateAction<boolean>>;
  alarmInfo: AlarmInfo | undefined;
};

const useEventChart = ({
  plant,
  startDate,
  endDate,
  options,
  fetchAlarms,
  id,
  title,
}: UseEventChartProps): UseEventChartReturn => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef();
  const [chartData, setChartData] = useState<ChartData<"line">>({
    labels: [],
    datasets: [],
  });
  const [mappingAlarmYvalues, setMappingAlarmYvalues] = useState<{[key: string]: number}>({});
  const [width, setWidth] = useState(0);
  const [openAlarmDialog, setOpenAlarmDialog] = useState(false);
  const [alarms, setAlarms] = useState<any[]>([]);
  const [alarmInfo, setAlarmInfo] = useState<AlarmInfo>();

  function openDialog(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
    if (chartRef.current !== undefined) {
      const chart = chartRef.current! as Chart;
      if (chart.crosshair.isZooming()) {
        return;
      }
      const alarm = getAlarmClicked(chartRef, alarms, event);
      if (alarm) {
        setAlarmInfo(alarm);
        setOpenAlarmDialog(true);
      }
    }
  }

  useEffect(() => {
    GraphResizeService.checkResizeWidth(ref, width, setWidth);
  }, [ref, width, setWidth]);

  useEffect(() => {
    const expectedDates = generateDates(startDate, endDate);

    async function updateDatasets(datasets: ChartDataset<"line">[]): Promise<void> {
      let alarms = await fetchAlarms(startDate, endDate, id);
      setAlarms(alarms);
      alarms = alarms.map((alarm) => {
        return {
          ...alarm,
          reference: alarm.reference.replace(/^(PLANT|METER|IMMERSION_HEATER|ESS|SOLAR_GATEWAY)_/, " "),
        };
      });

      const alarmYValues = mappingAlarmsToYvalues(alarms);
      setMappingAlarmYvalues(alarmYValues);
      for (let i = 0; i < alarms.length; i++) {
        datasets.push({
          label: `${alarms[i].reference}`,
          data: createAlarmTimeserie(expectedDates, alarms[i], alarmYValues[alarms[i].reference]),
          borderColor: eventLevelColor(alarms[i].level, theme),
          backgroundColor: eventLevelColor(alarms[i].level, theme),
          borderWidth: 10,
          segment: {
            borderDash: (ctx) => {
              const disconnectionAlarmIndexes = getCurrentAlarmIndexesFromReference(alarms, "DISCONNECT");
              return borderDashBasedOnDisconnectionAlarms(ctx, expectedDates, alarms, disconnectionAlarmIndexes, i);
            },
          },
        });
      }
    }

    const datasets: ChartDataset<"line">[] = [];
    const waitForDatasets = forkJoin({
      datasets: updateDatasets(datasets),
    });
    waitForDatasets.subscribe({
      next: () => {
        setChartData({
          labels: formatXLabels(expectedDates, plant),
          datasets: datasets,
        });
      },
      error: () => {
        setChartData({
          labels: formatXLabels(expectedDates, plant),
          datasets: [],
        });
      },
    });
  }, [startDate, endDate, plant, theme, fetchAlarms, id]);

  const chartHeight = 90 + 20 * Object.keys(mappingAlarmYvalues).length;

  const mergedOptions = useMemo<ChartOptions<"line">>(() => {
    const defaultOptions: ChartOptions<"line"> = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: title,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: (tooltipItem) => {
              if (chartData.datasets.length === 0) return "";
              return chartData.datasets[tooltipItem.datasetIndex].label;
            },
          },
        },
        annotation: {
          annotations: createYLabels(mappingAlarmYvalues, startDate.tz(plant.timezone), theme),
        },
      },
      scales: {
        y: {
          ticks: {
            stepSize: 1,
            autoSkip: false,
            callback: () => {
              return "";
            },
          },
          min: Math.min(...Object.values(mappingAlarmYvalues)) - 1,
          max: Math.max(...Object.values(mappingAlarmYvalues)) + 1,
        },
      },
    };
    return mergeObjectsRecursive(defaultOptions, options);
  }, [options, mappingAlarmYvalues, startDate, plant.timezone, theme, chartData.datasets]);

  return {
    chartData,
    options: mergedOptions,
    openDialog,
    ref,
    chartRef,
    width,
    chartHeight,
    setWidth,
    openAlarmDialog,
    setOpenAlarmDialog,
    alarmInfo,
  };
};

export default useEventChart;
