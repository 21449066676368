import {Divider} from "@mui/material";
import {Ess} from "../../../../../interfaces/ess/Ess";
import ControllerDetail from "./ControllerDetail";

interface propsInterface {
  ess: Ess;
}

export default function EssController(props: propsInterface) {
  return (
    <div>
      <p>
        {" "}
        {props.ess.name} se compose{" "}
        {props.ess.controllers.length == 1 ? "d'un contrôleur" : "de " + props.ess.controllers.length + " contrôleurs"}
      </p>
      {props.ess.controllers.map((controller) => {
        return (
          <div>
            <ControllerDetail controller={controller} />
            {props.ess.controllers.length == 1 ? <></> : <Divider style={{margin: "20px"}}></Divider>}
          </div>
        );
      })}
    </div>
  );
}
