import CardRow from "../CardRow";
import {SolarGateway} from "../../../../../interfaces/solar_gateway/SolarGateway";
import {formatDateTime} from "../../../../../service/format/FormatDateTime";
import {Divider} from "@mui/material";

interface propsInterface {
  solarGateway: SolarGateway;
}

export default function SolarGatewayGeneral(props: propsInterface) {
  return (
    <div>
      <h4>Général</h4>

      <CardRow label="Identifiant">{props.solarGateway.id}</CardRow>

      <CardRow label="Mise en route">{formatDateTime(props.solarGateway.operationDate)}</CardRow>

      <CardRow label="Référence">{props.solarGateway.type.label}</CardRow>

      <Divider style={{marginTop: "20px"}}></Divider>

      <h4>Logiciel</h4>

      <CardRow label="Version logicielle">{props.solarGateway.firmwareVersion}</CardRow>
    </div>
  );
}
