import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, {forwardRef, useImperativeHandle} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import apiEnedis from "../service/api/ApiEnedis";
import DownloadFileXMLHttp from "../service/file/DownloadFileXMLHttp";
import {GetConsumption} from "../interfaces/enedis/Consumption";
import ToastService from "../service/ToastService";
import Checkbox from "@mui/material/Checkbox";
import {Box, FormControlLabel, Typography} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import {Plant} from "../interfaces/Plant";
import ImperativeFormHandle from "../interfaces/ImperativeFormHandle";

type PlantFormProp = {
  handleCloseDialog: () => void;
  plant?: Plant;
  onDownloading: (downloading: boolean) => void;
};

const GetEnedisFileForm = forwardRef<ImperativeFormHandle, PlantFormProp>((props, ref) => {
  const {plant} = props;

  const {
    control,
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: {errors},
  } = useForm<GetConsumption>({
    defaultValues: {
      pdl: "",
      beginDate: "",
      endDate: "",
      validWarrant: false,
    },
  });

  const onSubmit: SubmitHandler<GetConsumption> = async (data) => {
    const url = apiEnedis.consumptionUrl(
      data.pdl,
      data.beginDate,
      data.endDate,
      plant !== undefined ? plant.id : undefined,
    );
    const strBeginDate = dayjs(data.beginDate).format("YYYY-MM-DD");
    const strEndDate = dayjs(data.endDate).format("YYYY-MM-DD");
    const prefix = plant ? `${plant.name}-${plant.id}-` : "";
    const filename = `${prefix}enedis-${data.pdl}-${strBeginDate}-${strEndDate}.csv`;

    props.onDownloading(true);
    DownloadFileXMLHttp.download(url, filename).then((xmlHTTP) => {
      xmlHTTP.onprogress = (progressHttp) => {
        const progressValue = (progressHttp.loaded / progressHttp.total) * 100;
        if (progressValue >= 100) {
          ToastService.success("Téléchargement terminé avec succès");
          props.onDownloading(false);
          props.handleCloseDialog();
        }
      };
      xmlHTTP.onerror = () => {
        ToastService.error("Erreur durant le téléchargement");
        props.onDownloading(false);
        props.handleCloseDialog();
      };
    });
  };

  useImperativeHandle(ref, () => ({
    submitForm() {
      trigger();
      handleSubmit(onSubmit)();
    },
  }));

  return (
    <Box sx={{width: "500px"}}>
      <Typography>
        Obtenez le fichier des consommations d'un client ou prospect qui a autorisé la consultation de ses données
        Enedis
      </Typography>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
        style={{marginTop: "20px"}}>
        <Box>
          <Controller
            name="pdl"
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                id="plantNameInput"
                type="text"
                variant="outlined"
                color="primary"
                placeholder="Point de livraison"
                label="Point de livraison"
                InputLabelProps={{shrink: true}}
                required
                {...register("pdl", {
                  required: "Le point de livraison est obligatoire",
                  pattern: {
                    value: /\d{14}/,
                    message: "Le point de livraison se compose de 14 chiffres",
                  },
                })}
                fullWidth
                error={!!errors.pdl}
                helperText={errors.pdl ? errors.pdl.message : null}
              />
            )}
          />

          <div style={{display: "flex"}}>
            <Controller
              name="beginDate"
              control={control}
              render={({field: {ref, onBlur, name, onChange, ...field}, fieldState}) => (
                <Box flex={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      label="Date de début"
                      minDate={dayjs().subtract(2, "year")}
                      maxDate={dayjs().subtract(2, "day")}
                      onChange={(event) => {
                        if (null !== event) {
                          onChange(dayjs(event));
                        }
                      }}
                      renderInput={(inputProps) => (
                        <TextField
                          {...inputProps}
                          onBlur={onBlur}
                          name={name}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              )}
              rules={{
                required: "La date de début est requise",
              }}
            />

            <Controller
              name="endDate"
              control={control}
              render={({field: {ref, onBlur, name, onChange, ...field}, fieldState}) => (
                <Box flex={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      label="Date de fin"
                      minDate={dayjs().subtract(2, "year")}
                      maxDate={dayjs().subtract(1, "day")}
                      onChange={(event) => {
                        if (null !== event) {
                          onChange(dayjs(event));
                        }
                      }}
                      renderInput={(inputProps) => (
                        <TextField
                          style={{marginLeft: "20px"}}
                          {...inputProps}
                          onBlur={onBlur}
                          name={name}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              )}
              rules={{
                required: "La date de fin est requise",
                validate: (value) => {
                  if (dayjs(value).isAfter(dayjs())) {
                    return "La date de fin ne peut pas être dans le futur";
                  }
                  if (dayjs(value).isBefore(dayjs(getValues().beginDate), "day")) {
                    return "La date de fin doit être après la date de début";
                  }
                  return true;
                },
              }}
            />
          </div>
        </Box>

        <Controller
          name="validWarrant"
          control={control}
          defaultValue={false}
          rules={{required: "Vous devez cocher cette case pour continuer"}}
          render={({field}) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />}
              label={
                <Typography>
                  J'ai en ma possession un mandat signé du client m'autorisant à accéder à ses données de consommation.
                  <br />
                  ATTENTION : les dates et le motif doivent correspondre !
                </Typography>
              }
            />
          )}
        />
        {errors.validWarrant && <FormHelperText error>{errors.validWarrant.message}</FormHelperText>}
      </form>
    </Box>
  );
});

export default GetEnedisFileForm;
