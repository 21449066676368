import {ReactNode, useEffect, useState} from "react";
import {Plant} from "../../../../../interfaces/Plant";
import {Trace, Information} from "../../../../../interfaces/trace/Trace";
import apiTrace from "../../../../../service/api/ApiTrace";
import {formatDateTime} from "../../../../../service/format/FormatDateTime";
import AppCustomWidthTooltip from "../../../../../component/AppCustomWidthTooltip";
import TraceOriginEnum from "../../../../../enums/TraceOriginEnum";
import TraceActionEnum from "../../../../../enums/TraceActionEnum";
import translateToHuman from "../../../../../service/TraceActionService";
import ToastService from "../../../../../service/ToastService";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Box, Card, CardContent, Typography} from "@mui/material";
import {frFR} from "@mui/x-data-grid/locales";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface PropsInterface {
  plant: Plant;
}

function tooltipInformation(value: string): ReactNode {
  return (
    <Box
      sx={{
        p: 2,
        minWidth: 300,
      }}>
      <Typography variant="body2">
        <pre>{value}</pre>
      </Typography>
    </Box>
  );
}

function furtherTraceInfo(type: string, information: Information): ReactNode {
  return (
    <AppCustomWidthTooltip placement={"right"} title={tooltipInformation(translateToHuman(information))}>
      <span>{translateToHuman(information, type)}</span>
    </AppCustomWidthTooltip>
  );
}

export default function PlantTraces(props: PropsInterface) {
  const [traces, setTraces] = useState<Trace[]>([]);

  useEffect(() => {
    apiTrace
      .traces(props.plant.id)
      .then((traces) => {
        if (!traces.length) {
          setTraces([]);
        } else {
          setTraces(traces);
        }
      })
      .catch(() => ToastService.error("Vous n'êtes pas autorisé à accéder à l'historique."));
  }, [props.plant.id]);

  const rows = traces.map((trace, i) => {
    return {...trace, id: i};
  });
  const columns: GridColDef<Trace>[] = [
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderCell: (params) => {
        return formatDateTime(params.row.date);
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => (
        <span
          title={TraceActionEnum[params.row.action as keyof typeof TraceActionEnum]}
          style={{overflow: "hidden", textOverflow: "ellipsis"}}>
          {TraceActionEnum[params.row.action as keyof typeof TraceActionEnum]}
        </span>
      ),
    },
    {
      field: "actor",
      headerName: "Utilisateur",
      width: 170,
      renderCell: (params) => {
        return params.row.actor_type !== "USER" ? (
          params.row.actor_type + params.row.actor
        ) : (
          <a href={`mailto:${params.row.actor}`}>{params.row.actor}</a>
        );
      },
    },
    {
      field: "origin",
      headerName: "Origine",
      width: 150,
      renderCell: (params) => {
        return (
          <span
            style={{overflow: "hidden", textOverflow: "ellipsis"}}
            title={TraceOriginEnum[params.row.origin as keyof typeof TraceOriginEnum]}>
            {TraceOriginEnum[params.row.origin as keyof typeof TraceOriginEnum]}
          </span>
        );
      },
    },
    {
      field: "info",
      headerName: "Information",
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{overflow: "hidden", textOverflow: "ellipsis"}}>
            {furtherTraceInfo(params.row.action, params.row.information)}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div
        style={{
          height: "100%",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          margin: "0px",
        }}>
        <div style={{flex: 1, overflow: "auto"}}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-footerContainer": {
                height: "35px",
                minHeight: "35px",
                overflow: "hidden",
              },
            }}
            rows={rows}
            columnHeaderHeight={40}
            rowHeight={40}
            columns={columns}
            rowSelection={false}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText} // FIXME : To remove when i18n will be implemented in app
          />
        </div>
        <Card style={{marginTop: "10px"}}>
          <CardContent style={{marginTop: "0px", padding: "0px"}}>
            <Typography sx={{display: "flex", alignItems: "center", gap: 1}}>
              <HelpOutlineIcon color="primary" />
              Pourquoi ne vois-je pas certaines actions dans l’historique ?
            </Typography>
            <Typography color="textSecondary">
              Les actions ne sont listées ici que depuis le moment où leur traçabilité a été mise en place. Les
              opérations antérieures ne sont pas présentes dans l’historique. Exemple : les actions concernant le
              bridage de l'injection ne sont toutes tracées que depuis février 2025.
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
