import {ChartOptions} from "chart.js";
import {Line} from "react-chartjs-2";
import {Plant} from "../../../../interfaces/Plant";
import {Dayjs} from "dayjs";
import apiV2 from "../../../../service/api/ApiV2";
import {Ess} from "../../../../interfaces/ess/Ess";
import {CrosshairPlugin} from "../../../../component/chartjs-plugin-crosshair/chartjs-plugin-crosshair";
import {ResizableBox} from "react-resizable";
import "react-resizable/css/styles.css";
import {AlarmDialog} from "../AlarmDialog";
import useEventChart from "../../../../hooks/useEventChart";

type EssEventChartProps = {
  plant: Plant;
  ess: Ess;
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
};

export function EssEventChart(props: EssEventChartProps) {
  const {
    chartData,
    options,
    openDialog,
    ref,
    width,
    chartHeight,
    chartRef,
    openAlarmDialog,
    setOpenAlarmDialog,
    alarmInfo,
  } = useEventChart({
    plant: props.plant,
    startDate: props.startDate,
    endDate: props.endDate,
    options: props.options,
    fetchAlarms: apiV2.essAlarms,
    id: props.ess.id,
    title: "Ev BESS",
  });

  return (
    <div ref={ref} className="scada-card-zoom">
      <ResizableBox
        transformScale={0.75}
        className="box"
        width={width}
        height={chartHeight}
        resizeHandles={["s"]}
        minConstraints={[100, chartHeight]}
        maxConstraints={[width, chartHeight * 4]}>
        {/* crosshair plugin is registered locally otherwise it can cause bug on non Line Chart*/}
        <Line
          ref={chartRef}
          onClick={openDialog}
          options={options}
          data={chartData}
          plugins={[CrosshairPlugin]}
          style={{marginLeft: "15px"}}
        />
      </ResizableBox>
      {alarmInfo && (
        <AlarmDialog open={openAlarmDialog} onClose={() => setOpenAlarmDialog(false)} alarmInfo={alarmInfo} />
      )}
    </div>
  );
}
