import {Box, Typography} from "@mui/material";
import {HelperDialog} from "../../../../component/molecules/HelperDialog";
export function HotwaterHelperDialog() {
  return (
    <HelperDialog title="Section stockage">
      <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
        Fichier de besoin en eau chaude
      </Typography>
      <Box>
        <Typography variant="body1" gutterBottom>
          - La période du fichier doit concorder avec la période d'étude de consommation.
        </Typography>
        <Typography variant="body1" gutterBottom>
          - Le pas peut être de 5min, 10min, 30min et 60min mais doit être le même pour l'ensemble du fichier.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Format RFC3339
        </Typography>
        <Typography variant="body1" gutterBottom>
          - Les dates sont au format <code>2023-10-28T20:00:00+02:00</code> (format excel texte, Date T heure UTC +
          décalage horaire) Il ne doit y avoir aucun doublon ou saut de date même pour les changements été/hiver.
        </Typography>
        <Typography variant="body1" gutterBottom>
          - La besoin en eau chaude est en Litre
        </Typography>
      </Box>
    </HelperDialog>
  );
}
