import SolarGatewayCard from "./SolarGatewayCard";
import {SolarGateway} from "../../../../../interfaces/solar_gateway/SolarGateway";

type SolarGatewayCardsProps = {
  solarGateways: {isSelected: boolean; solarGateway: SolarGateway}[];
};

export function SolarGatewayCards(props: SolarGatewayCardsProps) {
  return (
    <>
      {props.solarGateways.map((solarGateway) => {
        return (
          <SolarGatewayCard
            key={solarGateway.solarGateway.id}
            solarGateway={solarGateway.solarGateway}
            isSelected={solarGateway.isSelected}
          />
        );
      })}
    </>
  );
}
