import {useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppHelpButton from "../atom/button/AppHelpButton";

interface HelperDialogProps {
  title: string;
  children: React.ReactNode;
}

export function HelperDialog({title, children}: HelperDialogProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppHelpButton title={title} onClick={handleClickOpen} />
      <Dialog onClose={handleClose} open={open} maxWidth={false}>
        <DialogTitle style={{padding: 0}}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
              {title}
            </Typography>
            <IconButton
              color="secondary"
              style={{background: theme.palette.dialogCloseButtonBackground}}
              aria-label="close dialog"
              edge="start"
              onClick={handleClose}>
              <CloseIcon
                style={{
                  color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
                }}
              />
            </IconButton>
          </Toolbar>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions style={{paddingRight: "24px", paddingBottom: "24px"}}>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
