import {Box, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {Control, Controller} from "react-hook-form";
import Simulation from "../model";
import {ProductibleModeEnum} from "../ProductibleModeEnum";
import BetaLabel from "../../../../component/atom/label/BetaLabel";

type Props = {
  name: "productibleModeEnum";
  control: Control<Simulation>;
};

export function SelectProductible(props: Props) {
  const {name, control} = props;

  return (
    <FormControl>
      <Controller
        rules={{required: true}}
        control={control}
        name={name}
        render={({field}) => (
          <RadioGroup {...field} id={`productibleModeEnum-select`} style={{marginLeft: 15}}>
            <FormControlLabel
              key={ProductibleModeEnum.API_SOLAR_PRODUCTION}
              value={ProductibleModeEnum.API_SOLAR_PRODUCTION}
              style={{paddingBottom: 15}}
              control={<Radio />}
              label={
                <Box>
                  <Box>
                    <BetaLabel />
                    <b>{"Nouveau calcul (recommandé)"}</b>
                  </Box>
                  <Box>
                    Nous avons développé un nouveau mode de calcul du productible qui apporte 2 avantages:
                    <br /> les <u>études sont plus rapides</u>, et <u>le productible est normalisé</u>.<br />
                    Le terme "normalisé" signifie que le productible de la période de l'étude est réhaussé ou rabaissé
                    en fonction de la moyenne de productible de ces 6 dernières années. Les données restent ainsi liées
                    à la période de l'étude si bien que la corrélation avec les données de consommation du client est
                    conservée, tout en proposant un productible annuel global correspondant à la moyenne.
                  </Box>
                </Box>
              }
            />
            <FormControlLabel
              key={ProductibleModeEnum.API_PRODUCTIBLE}
              value={ProductibleModeEnum.API_PRODUCTIBLE}
              control={<Radio />}
              label={
                <Box>
                  <Box>
                    <b>{"Ancien calcul"}</b>
                  </Box>
                  <Box>
                    Durant cette période d'essai du nouveau calcul de productible, nous laissons l'ancien calcul à
                    disposition en cas de besoin, et pour vous permettre de comparer les résultats.
                    <br />
                    L'ancien calcul prend plus de temps de traitement et remonte le productible de la période analysée
                    sans tenir compte de la moyenne des dernières années. L'étude peut alors se révéler pessimiste ou
                    optimiste selon que l'ensoleillement sur la période ait été plus faible ou plus important que les
                    autres années.
                  </Box>
                </Box>
              }
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
