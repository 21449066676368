import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AppButton from "./AppButton";
import {Box} from "@mui/material";

export interface AppDrowdownItem {
  label: string | React.ReactNode;
}

interface Props {
  label?: string;
  icon?: React.ReactNode;
  items: AppDrowdownItem[];
}

export default function AppDropdown(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (props.items.length === 0) {
    return <></>;
  }

  return (
    <Box>
      <AppButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        label={props.label}
        icon={props.icon}
        style={"secondary"}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.items.map((item) => {
          return <MenuItem onClick={handleClose}>{item.label}</MenuItem>;
        })}
      </Menu>
    </Box>
  );
}
