import * as React from "react";
import {Controller} from "../../../../../interfaces/ess/Controller";
import CardRow from "../CardRow";

type Props = {
  controller: Controller;
};

export default function ControllerDetail(props: Props): React.JSX.Element {
  return (
    <div>
      <CardRow label="Modèle">{props.controller.type.brand}</CardRow>
      <CardRow label="Référence">{props.controller.type.label}</CardRow>
      <CardRow label="Adresse IP">{props.controller.ipAddress}</CardRow>
      <CardRow label="Seuil minimum">{props.controller.type.unloadedConsumption} %</CardRow>
      <CardRow label="Firmware version">{props.controller.firmwareVersion}</CardRow>
    </div>
  );
}
