import CardRow from "../CardRow";
import {formatDateTime} from "../../../../../service/format/FormatDateTime";
import {Ess} from "../../../../../interfaces/ess/Ess";

interface propsInterface {
  ess: Ess;
}

export default function EssGeneral(props: propsInterface) {
  return (
    <div>
      <h4>Général</h4>

      <CardRow label="Identifiant">{props.ess.id}</CardRow>

      <CardRow label="Référence">{props.ess.essTypeLabel}</CardRow>

      <CardRow label="Mise en route">{formatDateTime(props.ess.operatingDate)}</CardRow>

      <CardRow label="Capacité nominale">{props.ess.ratedCapacity} Wh</CardRow>

      <CardRow label="Puissance nominale">{props.ess.ratedPower} W</CardRow>
    </div>
  );
}
