enum TraceActionEnum {
  "UPDATE_RESTRICTED_POWER" = "Bridage activé/désactivé",
  "UPDATE_RESTRICTED_VALUE" = "Valeur de bridage (W)",
  "UPDATE_ENERGY_CALCULATION_METHOD" = "Mode de calcul",
  "UPDATE_CONSUMPTION_LEVEL" = "Mesure de consommation",
  "UPDATE_CAN_GENERATE_API_KEY" = "Option API client",
  "CREATE_API_KEY" = "Création d'une clé d'API",
  "HUMAN_ALARM_END_ALL" = "Purge des alarmes du tracker",
  "HUMAN_ALARM_END_ONE" = "Purge d'une alarme",
  "COMMAND_ALARM_END_ALL" = "Purge des alarmes par la commande",
  "GET_ENEDIS_CONSUMPTION" = "Téléchargement données Enedis",
  "UPDATE_LEGIONELLOSIS_PROTECTION" = "Protection légionellose",
}

export default TraceActionEnum;
