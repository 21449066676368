import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import SynchronisedDiv from "../SynchronisedDiv";
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import apiV2 from "../../../../../service/api/ApiV2";
import {forkJoin} from "rxjs";
import {MeterStatus} from "../../../../../interfaces/MeterStatus";
import {CardMedia, Checkbox, Grid, IconButton, Tooltip} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import image from "../../../../../assets/icon_meter.svg";
import {Meter} from "../../../../../interfaces/Meter";
import MeterDialog from "./MeterDialog";
import {useAppDispatch} from "../../../../../store/hooks";
import {toggleMeter} from "../../../../../store/scada/plant/PlantObjectsSlice";
import AppCollapse from "../AppCollapse";
import {AlarmsList} from "../AlarmsList";

type MeterCardProps = {
  meter: Meter;
  isSelected: boolean;
};

export default function MeterCard(props: MeterCardProps) {
  const theme = useTheme();
  const [meterStatus, setMeterStatus] = useState<MeterStatus>();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleMeter({id: props.meter.id, isSelected: event.target.checked}));
  };

  useEffect(() => {
    const status = forkJoin({
      meterStatus: apiV2.meterStatus(props.meter.id),
    });

    status.subscribe({
      next: (results) => {
        setMeterStatus(results.meterStatus);
      },
    });
  }, [props.meter.id]);
  if (!meterStatus) return <></>;
  return (
    <Card className="scada-card">
      <Grid container style={{paddingTop: "15px", paddingLeft: "10px"}}>
        <Grid item xs={2}>
          <CardMedia
            style={{
              backgroundColor: theme.palette.mode === "dark" ? "#757575" : "transparent",
              borderRadius: "50%",
              padding: "2px",
              marginLeft: "15px",
            }}
            component="img"
            image={image}
            className="scada-card-image"
          />
        </Grid>
        <Grid item xs={8} style={{paddingLeft: "10px"}}>
          <Typography component="div" className="scada-card-title">
            Compteur principal
          </Typography>
          <SynchronisedDiv isSynchronised={meterStatus.isSynchronised} />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            marginTop: "-5px",
            paddingRight: "10px",
            textAlign: "right",
          }}>
          <Checkbox onChange={toggleSelect} checked={props.isSelected} />
          <Tooltip title="Paramètres">
            <IconButton aria-label="delete" onClick={handleOpen}>
              <SettingsIcon fontSize="inherit" color="secondary" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            paddingLeft: "10px",
            paddingRight: "18px",
            paddingBottom: "10px",
          }}>
          <AppCollapse small={false} title="Alarmes">
            {meterStatus.alarms.length > 0 ? <AlarmsList alarms={meterStatus.alarms} /> : <p>Aucune alarmes</p>}
          </AppCollapse>
        </Grid>
      </Grid>
      <MeterDialog meter={props.meter} open={open} handleClose={handleClose}></MeterDialog>
    </Card>
  );
}
