import {SolarGateway} from "../../../../../interfaces/solar_gateway/SolarGateway";
import {translateUseCase} from "../../../../../service/format/FormatUseCase";
import CardRow from "../CardRow";

interface propsInterface {
  solarGateway: SolarGateway;
}

export default function SolarGatewaySetting(props: propsInterface) {
  return (
    <div>
      <h4>Configuration logiciel</h4>

      <CardRow label="Cas d'usage">{translateUseCase(props.solarGateway.useCase)}</CardRow>

      <CardRow label="Adresse IP">{props.solarGateway.tcpAddress}</CardRow>
    </div>
  );
}
