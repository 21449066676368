import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import SynchronisedDiv from "../SynchronisedDiv";
import {useEffect, useState} from "react";
import {forkJoin} from "rxjs";
import {CardMedia, Checkbox, Grid, IconButton, Chip, Tooltip} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import image from "../../../../../assets/ess/icon_ess.svg";
import {Ess} from "../../../../../interfaces/ess/Ess";
import {EssStatus} from "../../../../../interfaces/ess/EssStatus";
import apiV2 from "../../../../../service/api/ApiV2";
import AppCollapse from "../AppCollapse";
import {useAppDispatch} from "../../../../../store/hooks";
import {toggleEss} from "../../../../../store/scada/plant/PlantObjectsSlice";
import EssDialog from "./EssDialog";
import {useTheme} from "@mui/material/styles";

type EssCardProps = {
  ess: Ess;
  isSelected: boolean;
};

export default function EssCard(props: EssCardProps) {
  const [essStatus, setEssStatus] = useState<EssStatus>();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  function toggleSelect(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(toggleEss({id: props.ess.id, isSelected: event.target.checked}));
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const status = forkJoin({
      essStatus: apiV2.essStatus(props.ess.id),
    });

    status.subscribe({
      next: (results) => {
        setEssStatus(results.essStatus);
      },
    });
  }, [props.ess]);
  if (!essStatus) return <></>;
  return (
    <Card className="scada-card">
      <Grid container sx={{pt: 2, pl: 1}}>
        <Grid item xs={2}>
          <CardMedia
            style={{
              backgroundColor: theme.palette.mode === "dark" ? "#757575" : "transparent",
              borderRadius: theme.palette.mode === "dark" ? "50%" : "0px",
              padding: "2px",
              marginLeft: "15px",
            }}
            className="scada-card-image"
            component="img"
            image={image}
          />
        </Grid>
        <Grid item xs={8} sx={{pl: 1}}>
          <Typography component="div" className="scada-card-title">
            MEA STOCK - {props.ess.name}
          </Typography>
          <SynchronisedDiv isSynchronised={essStatus ? essStatus.isSynchronised : false} />
          {essStatus.isSynchronised && (
            <div>
              <Chip style={{marginTop: "5px"}} size="small" label={essStatus.essStatusData.power + " W"} />
              <Chip
                style={{marginTop: "5px", marginLeft: "5px"}}
                size="small"
                label={essStatus.essStatusData.level + " %"}
              />
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            marginTop: "-5px",
            paddingRight: "10px",
            textAlign: "right",
          }}>
          <Checkbox onChange={toggleSelect} checked={props.isSelected} />
          <Tooltip title="Paramètres">
            <IconButton aria-label="delete" onClick={handleOpen}>
              <SettingsIcon fontSize="inherit" color="secondary" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            paddingLeft: "10px",
            paddingRight: "18px",
            paddingBottom: "10px",
          }}>
          <AppCollapse small={false} title="Alarmes">
            <i>Bientôt disponible</i>
            {/* TODO: refactor ess full statues endpoint 
              {essStatus.alarms.length > 0 ? <AlarmsList alarms={essStatus.alarms} /> : <p>Aucune alarmes</p>} 
            */}
          </AppCollapse>
        </Grid>
      </Grid>
      <EssDialog ess={props.ess} open={open} handleClose={handleClose}></EssDialog>
    </Card>
  );
}
