import {Box, Typography, useTheme} from "@mui/material";
import {TrackerItem} from "./TrackerItem";
import trackerOkwind from "../../../../assets/tracker/okwind.png";
import trackerLumioo from "../../../../assets/tracker/lumioo.png";
import trackerTrk75 from "../../../../assets/tracker/TRK_75.png";
import tracker29000R from "../../../../assets/tracker/TREA_29000R.png";
import {TrackerType} from "../model/TrackerType";
import {TrackerTypeEnum} from "../model";

interface Props {
  value: string | null;
  onChange: (value: string) => void;
}

export function TrackerList(props: Props) {
  const theme = useTheme();

  const items: TrackerType[] = [
    {
      value: TrackerTypeEnum.TREA40000,
      label: "TREA 40000",
      imageUrl: trackerOkwind,
    },
    {
      value: TrackerTypeEnum.TREA29000R,
      label: "TREA 29000-R ",
      imageUrl: tracker29000R,
    },
    {
      value: TrackerTypeEnum.TRK75,
      label: "TRK 75",
      imageUrl: trackerTrk75,
    },
    {
      value: TrackerTypeEnum.LUMIOO,
      label: "Lumioo",
      imageUrl: trackerLumioo,
    },
  ];

  return (
    <Box>
      {items.length === 0 && (
        <Typography
          sx={{
            color: theme.palette.error.main,
          }}>
          Cette zone géographique n’est pas disponible pour le moment
        </Typography>
      )}
      {items.map((item) => {
        return (
          <TrackerItem
            key={`trackertype-${item.value}`}
            item={item}
            onClick={() => props.onChange(item.value)}
            isSelected={item.value === props.value}
          />
        );
      })}
    </Box>
  );
}
