import React, {useState} from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";

import {DialogProps, Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import CloseIcon from "@mui/icons-material/Close";
import TransitionSlideUp from "../../../../../service/transition/TransitionSlideUp";

import solar_gateway from "../../../../../assets/solarGateway/solar_gateway.png";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";

import {SolarGateway} from "../../../../../interfaces/solar_gateway/SolarGateway";
import SolarGatewayGeneral from "./SolarGatewayGeneral";
import SolarGatewaySetting from "./SolarGatewaySetting";
import SolarGatewayPlanning from "./SolarGatewayPlanning";

type SolarGatewayDialogProps = {
  solarGateway: SolarGateway;
  open: boolean;
  handleClose: () => void;
};

export default function SolarGatewayDialog(props: SolarGatewayDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maxWidth: DialogProps["maxWidth"] = "lg";

  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    props.handleClose();
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      fullScreen={isMobile}
      open={props.open}
      onClose={handleClose}
      style={{borderRadius: 22}}
      TransitionComponent={TransitionSlideUp}>
      <DialogTitle style={{padding: 0}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            {props.solarGateway.type.productName} - Numéro de série {props.solarGateway.serialNumber}
          </Typography>
          <IconButton
            color="secondary"
            style={{background: theme.palette.dialogCloseButtonBackground}}
            aria-label="close dialog"
            edge="start"
            onClick={props.handleClose}>
            <CloseIcon
              style={{
                color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
              }}
            />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <Divider style={{margin: 0}}></Divider>
      <DialogContent style={{minWidth: isMobile ? "100%" : "1000px"}}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <img
              alt="solar gateway"
              style={{
                width: "250px",
                display: isMobile ? "block" : "inline-block",
                margin: isMobile ? "auto" : "10px 0px 0px 10px",
              }}
              src={solar_gateway}
            />
          </Grid>
          <Grid item xs={12} md={8} style={{height: "340px", padding: "0px"}}>
            <TabContext value={value}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable auto tabs tracker">
                <Tab label="Informations" value="1" />
                <Tab label="Paramètres" value="2" />
                <Tab label="Planning" value="3" />
              </Tabs>
              <TabPanel value="1" style={{padding: "10px"}}>
                <SolarGatewayGeneral solarGateway={props.solarGateway}></SolarGatewayGeneral>
              </TabPanel>
              <TabPanel value="2" style={{padding: "10px"}}>
                <SolarGatewaySetting solarGateway={props.solarGateway}></SolarGatewaySetting>
              </TabPanel>
              <TabPanel
                value="3"
                style={{
                  height: "255px",
                  overflow: "overlay",
                  padding: "10px",
                }}>
                <SolarGatewayPlanning solarGateway={props.solarGateway}></SolarGatewayPlanning>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{paddingRight: "24px", paddingBottom: "24px"}}>
        <Button variant="outlined" color="inherit" onClick={props.handleClose}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
