import React, {RefObject, useImperativeHandle} from "react";
import Box from "@mui/material/Box";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {LegionellosisProtection} from "../../../../../../interfaces/immersion_heater/LegionellosisProtection";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../../../store/store";
import {updateLegionellosisProtection} from "../../../../../../store/scada/immersion_heater/immersionHeater.store";
import {Switch} from "@mui/material";
import ImperativeFormHandle from "../../../../../../interfaces/ImperativeFormHandle";

type LegionellosisProtectionFormProps = {
  formRef: RefObject<ImperativeFormHandle>;
  index: number;
  onSuccess: () => void;
};

const LegionellosisProtectionForm = (props: LegionellosisProtectionFormProps) => {
  const immersionHeaters = useSelector((state: RootState) => state.immersionHeaters);
  const dispatch = useDispatch<AppDispatch>();

  const {formRef, index} = props;

  const {control, handleSubmit, trigger} = useForm<LegionellosisProtection>({
    defaultValues: {
      immersionHeaterId: immersionHeaters.data[index].id,
      legionellosisProtection: immersionHeaters.data[index].legionellosisProtection,
    },
  });

  const onSubmit: SubmitHandler<LegionellosisProtection> = async (data) => {
    await dispatch(updateLegionellosisProtection(data));
    props.onSuccess();
  };

  // permet d appeler le submit form depuis le composant parent
  useImperativeHandle(formRef, () => ({
    submitForm() {
      trigger();
      handleSubmit(onSubmit)();
    },
  }));

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete={"off"}>
        <Box component="div" style={{marginTop: "-10px", padding: "0px"}}>
          <Controller
            name="legionellosisProtection"
            control={control}
            render={({field: {onChange, value}}) => <Switch checked={value} onChange={onChange} />}
          />
        </Box>
      </form>
    </>
  );
};

export default LegionellosisProtectionForm;
