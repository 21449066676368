import {ReactNode, useEffect, useState} from "react";
import {DataGrid, GridColDef, useGridApiRef} from "@mui/x-data-grid";
import {Link, Tooltip, Dialog, DialogTitle, DialogContent, Typography, Chip, useTheme} from "@mui/material";
import {useAppSelector} from "../../../../store/hooks";
import {CurrentAlarmItem} from "../../../../interfaces/CurrentAlarmItem";
import {useAppDispatch} from "../../../../store/hooks";
import {setOpen} from "../../../../store/scada/plants/AlarmSelectedSlice";
import {GetAlarmType} from "../../../../interfaces/alarms/GetAlarmType";
import apiV2 from "../../../../service/api/ApiV2";
import {durationShort, formatDateTime} from "../../../../service/format/FormatDateTime";
import dayjs from "dayjs";
import {eventLevelColor} from "../../plant/AlarmService";

type AlarmsTableProps = {
  value: Array<CurrentAlarmItem>;
};

export default function AlarmsTable(props: AlarmsTableProps) {
  const apiRef = useGridApiRef();
  const alarmSelected = useAppSelector((state) => state.alarmSelected.value);
  const [alarmTypes, setAlarmTypes] = useState<GetAlarmType[]>([]);
  const open = useAppSelector((state) => state.alarmSelected.open);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  useEffect(() => {
    apiV2.getAlarmTypes().then(setAlarmTypes);
  }, []);

  const alarmTypeSelected = alarmTypes.find((x) => x.alarmReference === alarmSelected);

  function plantLink(plantId: number, value: string): ReactNode {
    return (
      <Tooltip title={value}>
        <Link color="secondary" href={`/scada/plant?plant_id=${plantId}`}>
          {value}
        </Link>
      </Tooltip>
    );
  }

  const columns: GridColDef<CurrentAlarmItem>[] = [
    {
      field: "plantName",
      headerName: "Installation",
      width: 250,
      sortable: true,
      renderCell: (params) => plantLink(params.row.plantId, params.value),
    },
    {
      field: "begin",
      headerName: "Date de début",
      width: 250,
      sortable: true,
      renderCell: (params) => (
        <>
          <span>{formatDateTime(params.row.begin)}</span>
          <span style={{color: "grey", marginLeft: "5px"}}>({durationShort(dayjs(params.row.begin), dayjs())})</span>
        </>
      ),
    },
  ];

  let rows = props.value.filter((x) => x.alarmTypeReference === alarmSelected);
  // add an id to each row
  rows = rows.map((x, i) => {
    return {...x, id: i};
  });
  // order by plantName
  rows.sort((a, b) => a.plantName.localeCompare(b.plantName));

  return (
    <>
      {alarmTypeSelected && (
        <Dialog open={open} sx={{borderRadius: 10}} onClose={() => dispatch(setOpen(false))}>
          <DialogTitle>{alarmTypeSelected.label}</DialogTitle>
          <DialogContent>
            <Chip
              size="small"
              label={alarmTypeSelected.alarmReference}
              sx={{
                backgroundColor: eventLevelColor(alarmTypeSelected.alarmLevel, theme),
                color: "#000",
                fontWeight: "bold",
                mb: "10px",
              }}
            />
            {alarmTypeSelected.description && (
              <Typography variant="body2" sx={{mb: "10px"}}>
                {alarmTypeSelected.description}
              </Typography>
            )}
            {alarmTypeSelected.technicalTroubleshooting && (
              <Typography variant="body2" sx={{mb: "10px"}}>
                {alarmTypeSelected.technicalTroubleshooting}
              </Typography>
            )}
            <DataGrid
              style={{height: "85%"}}
              rows={rows}
              columns={columns}
              apiRef={apiRef}
              rowHeight={30}
              columnHeaderHeight={40}
              hideFooterSelectedRowCount
              initialState={{
                pagination: {
                  paginationModel: {pageSize: 13, page: 0},
                },
                sorting: {
                  sortModel: [{field: "begin", sort: "desc"}],
                },
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
