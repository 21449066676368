import {Alert, AlertColor, AlertTitle, useTheme, alpha} from "@mui/material";
import {Alarm} from "../../../../interfaces/Alarm";
import {useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import {AlarmDialog} from "../AlarmDialog";
import {useState} from "react";
import {AlarmInfo} from "../../../../interfaces/AlarmInfo";

type AlarmDetailProps = {
  alarm: Alarm;
};

const getSeverityFromLevel = (level: number): AlertColor => {
  if (level >= 30) return "error";
  if (level >= 20) return "warning";
  if (level >= 10) return "success";
  return "info";
};

export const AlarmDetail = (props: AlarmDetailProps) => {
  const dateFormated = dayjs(props.alarm.begin);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openAlarmDialog, setOpenAlarmDialog] = useState(false);
  const theme = useTheme();

  const openScadaOnAlarmBeginDate = (event: React.MouseEvent): void => {
    event.stopPropagation();
    searchParams.set("start_date", dateFormated.subtract(1, "day").format("YYYY-MM-DD"));
    searchParams.set("end_date", dateFormated.add(1, "day").format("YYYY-MM-DD"));
    setSearchParams(searchParams);
  };

  const alarmInfo = {
    id: props.alarm.id,
    label: props.alarm.type.label,
    reference: props.alarm.type.reference,
    level: props.alarm.type.level,
    description: props.alarm.type.description,
    technicalTroubleshooting: props.alarm.type.technicalTroubleshooting,
    beginDate: props.alarm.begin,
    endDate: null,
  } as AlarmInfo;

  const backgroundColor = {
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
    info: theme.palette.info.main,
    success: theme.palette.success.main,
  }[getSeverityFromLevel(props.alarm.type.level)];

  return (
    <>
      <Alert
        sx={{
          marginTop: "10px",
          fontSize: "12px",
          cursor: "pointer",
          transition: "background-color 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: alpha(backgroundColor, 0.3), // Less transparent on hover
          },
        }}
        severity={getSeverityFromLevel(props.alarm.type.level)}
        onClick={() => setOpenAlarmDialog(true)}>
        <AlertTitle sx={{fontWeight: "bold", fontSize: "12px"}}>{props.alarm.type.label}</AlertTitle>
        <i>
          Depuis le{" "}
          <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={openScadaOnAlarmBeginDate}>
            {dateFormated.format("DD/MM/YYYY HH:mm")}
          </span>
        </i>
      </Alert>
      <AlarmDialog open={openAlarmDialog} onClose={() => setOpenAlarmDialog(false)} alarmInfo={alarmInfo} />
    </>
  );
};
