import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Box,
  IconButton,
  Chip,
  useTheme,
  Divider,
} from "@mui/material";
import {AlarmInfo} from "../../../interfaces/AlarmInfo";
import CloseIcon from "@mui/icons-material/Close";
import {duration, formatDateTime} from "../../../service/format/FormatDateTime";
import {eventLevelColor} from "./AlarmService";
import dayjs from "dayjs";
import apiV2 from "../../../service/api/ApiV2";
import ConfirmDialogService from "../../../service/ConfirmDialogService";
import {StatusCodes} from "http-status-codes";
import ToastService from "../../../service/ToastService";
import {useNavigate} from "react-router-dom";

type AlarmDialogProps = {
  open: boolean;
  onClose: () => void;
  alarmInfo: AlarmInfo;
};

export function AlarmDialog(props: AlarmDialogProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const endAlarmOnClick = () => {
    ConfirmDialogService.warning(
      "md",
      "Acquittement d'une alarme",
      "Êtes-vous sur de vouloir acquitter cette alarme ?",
      "<strong>Cette action va acquitter l'alarme en cours.</strong>",
      () => {
        apiV2.alarmEnd(props.alarmInfo.id, dayjs.utc().format()).then((response) => {
          if (response.status === StatusCodes.ACCEPTED) {
            ToastService.success("Demande d'acquittement envoyée.");
            // refresh page
            navigate(0);
          } else {
            ToastService.error("Échec de l'acquittement.");
          }
        });
      },
    );
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        {props.alarmInfo.label}
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {/* Alarm Details Section */}
        <h4>Détails de l'alarme</h4>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="body2">Code</Typography>
          </Grid>
          <Grid item xs={8}>
            <Chip
              size="small"
              label={props.alarmInfo.reference}
              sx={{backgroundColor: eventLevelColor(props.alarmInfo.level, theme), color: "#000", fontWeight: "bold"}}
            />
          </Grid>
          <AlarmDialogRow
            label="Description"
            value={
              props.alarmInfo.description === "" ? "Aucune information complémentaire" : props.alarmInfo.description
            }
          />
          <AlarmDialogRow
            label="Dépannage"
            value={
              props.alarmInfo.technicalTroubleshooting === ""
                ? "Aucune information complémentaire"
                : props.alarmInfo.technicalTroubleshooting
            }
          />
        </Grid>
        <Divider sx={{marginTop: "20px"}}></Divider>
        {/* Occurrence Section */}
        <Box mt={2}>
          <h4>Occurrence</h4>
          <Grid container spacing={1}>
            <AlarmDialogRow label="Date de début" value={formatDateTime(props.alarmInfo.beginDate)} />
            <Grid item xs={4}>
              <Typography variant="body2">Date de fin</Typography>
            </Grid>
            <Grid item xs={props.alarmInfo.endDate === null ? 3 : 8}>
              <Typography variant="body2">
                {formatDateTime(props.alarmInfo.endDate) === "N/A"
                  ? "Alarme en cours ..."
                  : formatDateTime(props.alarmInfo.endDate)}
              </Typography>
            </Grid>
            {props.alarmInfo.endDate === null && (
              <Grid item xs={5}>
                <span
                  style={{cursor: "pointer", textDecoration: "underline", color: theme.palette.info.main}}
                  onClick={endAlarmOnClick}>
                  Forcer l'acquittement
                </span>
              </Grid>
            )}
            <AlarmDialogRow
              label="Durée"
              value={
                props.alarmInfo.endDate === null
                  ? duration(dayjs(props.alarmInfo.beginDate), dayjs())
                  : duration(dayjs(props.alarmInfo.beginDate), dayjs(props.alarmInfo.endDate))
              }
            />
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="outlined" color="inherit">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AlarmDialogRow(props: {label: string; value: string}) {
  return (
    <>
      <Grid item xs={4}>
        <Typography variant="body2">{props.label}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2">{props.value}</Typography>
      </Grid>
    </>
  );
}
