import {Information} from "../interfaces/trace/Trace";
import {RootState} from "../store/store";
import {formatConsumptionLevel} from "./format/FormatConsumptionLevel";
import {useSelector} from "react-redux";

/**
 * permet de remplacer la fin d une chaine de caractères > 24 par [...]
 * @param value string
 * @returns truncate value
 */
const truncateValue = (value: string) => {
  if (value.length > 24) {
    return value.slice(0, 24) + "...";
  }
  return value;
};

const defaultFormat = (information: Information) => {
  /**
   * decompose le json en table clé valeur avec entries puis replace la valeur par defaut de chaque attribut par un truncateValue
   * une fois la valeur de l attribut remplacé on recompose le json avec fromEntries
   */
  const truncatedInformation = Object.fromEntries(
    Object.entries(information).map(([key, value]) => [key, truncateValue(value)]),
  );

  return JSON.stringify(truncatedInformation, null, "\t");
};

const translateEnableDisable = (information: Information) => {
  if (information.value === false) {
    return "Désactivé";
  }
  if (information.value === true) {
    return "Activé";
  }
  return defaultFormat(information);
};

const translateUpdateEnergyCalculationMethod = (information: Information) => {
  if (information.value === true) {
    return "Énergie (W/h)";
  }
  if (information.value === false) {
    return "Puissance (W)";
  }
  return defaultFormat(information);
};

const translateUpdateRestrictedValue = (information: Information) => {
  return `${information.value} W`;
};

const translateUpdateConsumptionLevel = (information: Information) => {
  const formattedConsumptionLevel = formatConsumptionLevel(information.value);
  if (formattedConsumptionLevel === "N/A") {
    return defaultFormat(information);
  }
  return formattedConsumptionLevel;
};

const translateHumanAlarmEndOne = (information: Information) => {
  return information.alarm_reference;
};

const translateGetEnedisConsumption = (information: Information) => {
  return `Du ${information.start_date} au ${information.end_date}`;
};

const translateUpdateLegionellosisProtection = (information: Information) => {
  const immersionHeaters = useSelector((state: RootState) => state.immersionHeaters);
  const immersionHeater = immersionHeaters.data.find(
    (immersionHeater) => immersionHeater.id === information.immersion_heater_id,
  );
  return `${immersionHeater?.alias} : ${information?.value ? "Activée" : "Desactivée"}`;
};

const translateToHuman = (information: Information, type?: string) => {
  switch (type) {
    case "UPDATE_RESTRICTED_POWER":
    case "UPDATE_CAN_GENERATE_API_KEY":
      return translateEnableDisable(information);
    case "UPDATE_ENERGY_CALCULATION_METHOD":
      return translateUpdateEnergyCalculationMethod(information);
    case "UPDATE_RESTRICTED_VALUE":
      return translateUpdateRestrictedValue(information);
    case "UPDATE_CONSUMPTION_LEVEL":
      return translateUpdateConsumptionLevel(information);
    case "HUMAN_ALARM_END_ONE":
      return translateHumanAlarmEndOne(information);
    case "GET_ENEDIS_CONSUMPTION":
      return translateGetEnedisConsumption(information);
    case "UPDATE_LEGIONELLOSIS_PROTECTION":
      return translateUpdateLegionellosisProtection(information);
    default:
      return defaultFormat(information);
  }
};

export default translateToHuman;
