import {Box, Typography} from "@mui/material";
import {HelperDialog} from "../../../../component/molecules/HelperDialog";
export function ConsumptionHelperDialog() {
  return (
    <HelperDialog title="Section consommation">
      <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
        Fichier de consommation
      </Typography>
      <Box>
        <Typography variant="h6" gutterBottom>
          Format SIZEA (RFC3339)
        </Typography>
        <Typography variant="body1" gutterBottom>
          - Les dates sont au format <code>2023-10-28T20:00:00+02:00</code> (format excel texte)
        </Typography>
        <Typography variant="body1" gutterBottom>
          - La puissance est en Watt
        </Typography>

        <Typography variant="h6" gutterBottom>
          Format SIZEA (date excel)
        </Typography>
        <Typography variant="body1" gutterBottom>
          - Les dates sont au format excel date, présumées en UTC
        </Typography>
        <Typography variant="body1" gutterBottom>
          - La puissance est en Watt
        </Typography>

        <Typography variant="h6" gutterBottom>
          Format Enedis monocolonne (SGE)
        </Typography>
        <Typography variant="body1" gutterBottom>
          Le fichier monocolonne est celui ayant 3 lignes d'entête et dont la première colonne contient la date avec un
          décalage UTC (le +01:00)
        </Typography>
        <Typography variant="body1" gutterBottom>
          - Les dates sont au format <code>2022-01-31T00:00:00+01:00</code> (format excel texte), la date ne pose pas de
          problème car le décalage UTC est inclus dans le texte
        </Typography>
        <Typography variant="body1" gutterBottom>
          - La puissance est en Watt
        </Typography>

        <Typography variant="h6" gutterBottom>
          Format Enedis multicolonne (M021 version dates tirets)
        </Typography>
        <Typography variant="body1" gutterBottom>
          Le fichier multicolonne est celui ayant 1 ligne d'entête et dont la première colonne contient la date au
          format <code>21-01-2021</code> et la seconde colonne est l'heure au format <code>00:00</code>
        </Typography>
        <Typography variant="body1" gutterBottom>
          - Les dates sont au format <code>21-01-2021</code> (format excel texte) en colonne A et <code>00:00</code>{" "}
          (format excel texte) en colonne B, la date et l'heure sont supposées être localisées à Paris (il y a donc des
          changements d'heures)
        </Typography>
        <Typography variant="body1" gutterBottom>
          - La puissance est en Watt
        </Typography>

        <Typography variant="h6" gutterBottom>
          Format Enedis multicolonne (M021 version dates slashes)
        </Typography>
        <Typography variant="body1" gutterBottom>
          Le fichier multicolonne est celui ayant 1 ligne d'entête et dont la première colonne contient la date au
          format <code>21/01/2021</code> et la seconde colonne est l'heure au format <code>00:00</code>
        </Typography>
        <Typography variant="body1" gutterBottom>
          - Les dates sont au format <code>21/01/2021</code> (format excel texte) en colonne A et <code>00:00</code>{" "}
          (format excel texte) en colonne B, la date et l'heure sont supposées être localisées à Paris (il y a donc des
          changements d'heures)
        </Typography>
        <Typography variant="body1" gutterBottom>
          - La puissance est en Watt
        </Typography>
      </Box>
    </HelperDialog>
  );
}
