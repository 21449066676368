import {Divider} from "@mui/material";

import CardRow from "../CardRow";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/store";
import MapAddress from "../../../../../component/form/MapAddress";
import {Tracker} from "../../../../../interfaces/Tracker";

interface propsInterface {
  index: number;
}

export function TrackerSetting(props: propsInterface) {
  const trackers = useSelector((state: RootState) => state.trackers);

  const hasTracking = (tracker: Tracker) => {
    return tracker.trackerTypeLabel != "Structure au sol";
  };

  return (
    <div>
      <h4>Configuration logiciel</h4>

      {hasTracking(trackers.data[props.index]) && (
        <CardRow label="Offset inclinaison">{trackers.data[props.index].offsetElevation}</CardRow>
      )}
      {hasTracking(trackers.data[props.index]) && (
        <CardRow label="Offset azimuth">{trackers.data[props.index].offsetHeading}</CardRow>
      )}
      <CardRow label="Maitre">
        {trackers.data[props.index].isMaster ? "Oui" : trackers.data[props.index].isMaster === false ? "Non" : ""}
      </CardRow>
      <CardRow label="Modbus serveur activé">
        {trackers.data[props.index].modbusServerActivated ? "Oui" : "Non"}
      </CardRow>

      <div style={{marginTop: "20px"}}>
        <Divider></Divider>
      </div>

      <h4>Coordonnées GPS</h4>

      <CardRow label="Latitude">{trackers.data[props.index].latitude}</CardRow>
      <CardRow label="Longitude">{trackers.data[props.index].longitude}</CardRow>

      <div style={{marginTop: "20px"}}>
        <Divider></Divider>
        <h4>Carte</h4>
        <MapAddress
          latitude={Number(trackers.data[props.index].latitude)}
          longitude={Number(trackers.data[props.index].longitude)}
          precision={4}
          mapWidth="99%"
          mapHeight="330px"
        />
      </div>
    </div>
  );
}
