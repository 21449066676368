import * as React from "react";
import CardRow from "../CardRow";
import {Converter} from "../../../../../interfaces/ess/Converter";

type Props = {
  converter: Converter;
};

export default function ConverterDetail(props: Props): React.JSX.Element {
  return (
    <div>
      <CardRow label="Fabricant">{props.converter.type.brand}</CardRow>
      <CardRow label="Référence">{props.converter.type.label}</CardRow>
      <CardRow label="Puissance nominale">{props.converter.type.ratedPower} W</CardRow>
      <CardRow label="Efficience AC/DC">{props.converter.type.acDcEfficiency} %</CardRow>
      <CardRow label="Efficience DC/AC">{props.converter.type.dcAcEfficiency} %</CardRow>
      <CardRow label="Firmware version">{props.converter.firmwareVersion}</CardRow>
    </div>
  );
}
