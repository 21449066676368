import Map, {Layer, Popup, Source, FullscreenControl} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {useEffect, useState} from "react";
import type {FeatureCollection} from "geojson";
import {Grid, Menu, MenuItem, useTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {MapOfPlantStatusesItem} from "../../../../interfaces/MapOfPlantStatusesItem";
import {REACT_APP_MAPBOX_TOKEN} from "../../../../config";
import dayjs from "dayjs";
import {CircleLayerSpecification} from "mapbox-gl";

const mapboxToken = REACT_APP_MAPBOX_TOKEN;

function popupRow(
  theme: Theme,
  label: string,
  value: string,
  fontSize: string = "12px",
  isValueArrayLike: boolean = false,
): ReactJSXElement {
  return (
    <Grid
      container
      style={{
        backgroundColor: theme.palette.mode === "dark" ? "rgb(18, 18, 18)" : theme.palette.common.white,
      }}>
      <Grid item xs={3} className="map-tooltip-item">
        <b>{label}</b>
      </Grid>
      <Grid item xs={9}>
        {isValueArrayLike &&
          value.split(",").map((x, index) => (
            <div style={{fontSize: fontSize}} key={index}>
              {x}
            </div>
          ))}
        {!isValueArrayLike && <div style={{fontSize: fontSize}}>{value}</div>}
      </Grid>
    </Grid>
  );
}

function popupContentElement(
  theme: Theme,
  plantId: string,
  plantName: string,
  alarmTypeReferences: string,
  tags: string,
  interventionDate: string,
): ReactJSXElement {
  return (
    <Grid
      container
      style={{
        padding: 10,
        borderRadius: 8,
        backgroundColor: theme.palette.mode === "dark" ? "rgb(18, 18, 18)" : theme.palette.common.white,
      }}>
      {popupRow(theme, "Id site", plantId)}
      {popupRow(theme, "Site", plantName)}
      {popupRow(theme, "Alarmes", alarmTypeReferences, "10px", true)}
      {tags !== "" && popupRow(theme, "Tags", tags, "10px")}
      {interventionDate !== "" && popupRow(theme, "Intervention", interventionDate, "10px")}
    </Grid>
  );
}

type MapOfPlantStatusesProps = {
  value: Array<MapOfPlantStatusesItem>;
};

export default function MapOfPlantStatuses(props: MapOfPlantStatusesProps) {
  const [markers, setMarkers] = useState<FeatureCollection>();
  const [longitudePopup, setPopupLongitude] = useState<number>();
  const [latitudePopup, setPopupLatitude] = useState<number>();
  const [popupContent, setPopupContent] = useState<ReactJSXElement>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [contextMenu, setContextMenu] = useState<{x: number; y: number; plantId?: string} | null>(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const mapStyle =
    theme.palette.mode === "dark"
      ? "mapbox://styles/mapbox/dark-v11?optimize=true"
      : "mapbox://styles/mapbox/streets-v9?optimize=true";

  useEffect(() => {
    // sort the status by level in order to display plants with alarms over plants active
    // not synchronised plant are shown in last
    const data = props.value.sort((a, b) =>
      !a.status.isSynchronised ? -1 : a.status.statusType?.level - b.status.statusType?.level,
    );
    setMarkers({
      type: "FeatureCollection",
      features: data.map((x) => {
        return {
          type: "Feature",
          properties: {
            plantId: x.plantId,
            plantName: x.plantName,
            alarmTypeReferences: x.status.alarmTypeReferences?.join(", "),
            alarmLevel: x.status.statusType?.level,
            isSynchronised: x.status.isSynchronised,
            latitude: x.latitude,
            longitude: x.longitude,
            tags: x.tags.join(","),
            interventionDate: x.interventionDate ? dayjs(new Date(x.interventionDate)).format("DD/MM/YYYY") : "",
          },
          geometry: {type: "Point", coordinates: [x.longitude, x.latitude]},
        };
      }),
    });
  }, [props.value]);

  const markerStyle: CircleLayerSpecification = {
    id: "markers",
    type: "circle",
    source: "scada",
    paint: {
      "circle-radius": ["interpolate", ["linear"], ["zoom"], 4, 3, 10, 8],
      "circle-color": [
        "case",
        ["==", ["get", "isSynchronised"], false],
        theme.palette.notSynchronised,
        ["==", ["get", "alarmLevel"], 10],
        theme.palette.success.main,
        ["==", ["get", "alarmLevel"], 20],
        theme.palette.warning.main,
        ["==", ["get", "alarmLevel"], 30],
        theme.palette.error.main,
        theme.palette.noData,
      ],
    },
  };

  function goToSpecificPlant(event: mapboxgl.MapMouseEvent) {
    if (event.features?.length) {
      navigate(`/scada/plant?plant_id=${event.features[0].properties?.plantId}`);
    }
  }

  function mouseEnter(event: mapboxgl.MapMouseEvent) {
    if (event.features?.length) {
      setPopupLatitude(event.features[0].properties?.latitude);
      setPopupLongitude(event.features[0].properties?.longitude);
      setPopupContent(
        popupContentElement(
          theme,
          event.features[0].properties?.plantId,
          event.features[0].properties?.plantName,
          event.features[0].properties?.alarmTypeReferences,
          event.features[0].properties?.tags,
          event.features[0].properties?.interventionDate,
        ),
      );
      setShowPopup(true);
    }
  }

  function mouseLeave() {
    setShowPopup(false);
  }

  const handleRightClick = (event: mapboxgl.MapMouseEvent) => {
    event.preventDefault();
    setShowPopup(false);

    if (event.features?.length) {
      const plantId = event.features[0].properties?.plantId;
      setContextMenu({
        x: event.originalEvent.clientX, // Get position of the right-click
        y: event.originalEvent.clientY,
        plantId,
      });
    }
  };

  const handleContextMenuAction = () => {
    if (contextMenu?.plantId) {
      window.open(`/scada/plant?plant_id=${contextMenu.plantId}`, "_blank");
    }
    setContextMenu(null); // Close the menu after clicking
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  return (
    <>
      <Map
        mapboxAccessToken={mapboxToken}
        initialViewState={{
          longitude: 1,
          latitude: 47,
          zoom: 4.7,
        }}
        interactiveLayerIds={["markers"]} // must be the same as the layer id in order to trigger callback only on layer element
        style={{
          width: "100%",
          height: "500px",
          borderRadius: "10px",
          flex: 1,
          overflow: "hidden",
        }}
        mapStyle={mapStyle}
        onClick={goToSpecificPlant}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onContextMenu={handleRightClick}>
        <Source type="geojson" data={markers}>
          <Layer {...markerStyle} />
        </Source>
        {showPopup && (
          <Popup
            longitude={longitudePopup!}
            latitude={latitudePopup!}
            style={{minWidth: 350, maxWidth: 350}} // setting min and max width enable to avoid overlaping when zooming
          >
            {popupContent}
          </Popup>
        )}
        <FullscreenControl />
      </Map>
      <Menu
        open={!!contextMenu}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu ? {top: contextMenu.y, left: contextMenu.x} : undefined}>
        <MenuItem onClick={handleContextMenuAction}>Voir l'installation dans SCADA</MenuItem>
      </Menu>
    </>
  );
}
